export const GET_LISTINGS_DETAILS_BY_ACCOUNT = `
import AFLMarketplaceV2 from 0xAFLContracts

pub fun main(addr:Address): AnyStruct {
  let account = getAccount(addr)
  let acctCapability =  account.getCapability(/public/AFLSaleCollection2)
                          .borrow<&{AFLMarketplaceV2.SalePublic}>()
  if acctCapability == nil {
    return []
  } else {
    return acctCapability!.getDetails()
  }
}
`

export const GET_LEGACY_LISTINGS_DETAILS_BY_ACCOUNT = `
import AFLMarketplace from 0xAFLContracts

pub fun main(addr:Address): AnyStruct {
  let account = getAccount(addr)
  let acctCapability =  account.getCapability(/public/AFLSaleCollection)
                          .borrow<&{AFLMarketplace.SalePublic}>()
  if acctCapability == nil {
    return []
  } else {
    return acctCapability!.getDetails()
  }
}
`
