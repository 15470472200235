import * as Sentry from '@sentry/browser'
import { useState, useEffect } from 'react'
import * as fcl from '@blocto/fcl'
import { useTransactions } from 'providers/TransactionsProvider'
import { useWallets } from 'providers/WalletsProvider'
import { waitForSealed } from 'utils/flow'
import { UPGRADE_NFT } from 'flow/transactions/upgradeNFT.tx'
import { GET_UPGRADE_PATHS } from 'flow/scripts/getUpgradePaths.script'
import { GET_UPGRADE_REQUIREMENTS } from 'flow/scripts/getUpgradeRequirements.script'

export enum ExchangeStatus {
  LOADING,
  IDLE,
  EXCHANGING,
  DONE,
  ERROR
}

export default function useUpdateMoments(templateId?: string) {
  const [status, setStatus] = useState<ExchangeStatus>(ExchangeStatus.IDLE)
  const { addTx } = useTransactions()
  const { userAuth } = useWallets()
  const [upgradePaths, setUpgradePaths] = useState<{ [key: string]: string }>(
    {}
  )
  const [upgradeRequirements, setUpgradeRequirements] = useState<any>(undefined)

  useEffect(() => {
    getUpgradePaths()
  }, [])

  useEffect(() => {
    if (templateId) {
      getUpgradeRequirements(templateId).then(setUpgradeRequirements)
    }
  }, [templateId])

  async function updateMoments(
    upgradeMomentIds: string[],
    burnMomentIds: string[]
  ) {
    if (!upgradeMomentIds || !burnMomentIds) return
    try {
      setStatus(ExchangeStatus.EXCHANGING)
      const transactionId = await fcl.send([
        fcl.transaction(UPGRADE_NFT),
        fcl.args([
          fcl.arg(upgradeMomentIds, fcl.t.Array(fcl.t.UInt64)),
          fcl.arg(burnMomentIds, fcl.t.Array(fcl.t.UInt64))
        ]),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(
        transactionId.transactionId,
        'Upgrading your NFT'.concat(upgradeMomentIds.length > 1 ? 's' : '')
      )
      await waitForSealed(transactionId.transactionId)
      setStatus(ExchangeStatus.DONE)
    } catch (e) {
      console.error(e)
      setStatus(ExchangeStatus.ERROR)
      Sentry.captureException(e)
      throw e
    }
  }

  async function getUpgradePaths() {
    const response = await fcl.query({
      cadence: GET_UPGRADE_PATHS
    })

    setUpgradePaths(response)
    return response as { [key: string]: string }
  }

  type UpgradeRequirements = {
    quantity: string
    templateIds: [string]
  }[]

  async function getUpgradeRequirements(
    templateId: string
  ): Promise<UpgradeRequirements> {
    console.log('getting upgrade requirements for', templateId)
    const response = await fcl.query({
      cadence: GET_UPGRADE_REQUIREMENTS,
      args: (arg, t) => [arg(templateId, fcl.t.UInt64)]
    })
    console.log('upgrade requirements', { response })
    return response as UpgradeRequirements
  }

  return {
    status,
    updateMoments,
    upgradePaths,
    upgradeRequirements
  }
}
