import { getWishList, upsertWishlist } from '../services/wishlist.service.api'

export const WATCHLIST_LOCALSTORAGE_KEY = `watchlistTemplateIds`

export const getWatchlistIds = () => {
  const watchListIds = JSON.parse(
    localStorage.getItem(WATCHLIST_LOCALSTORAGE_KEY) || '[]'
  )

  return watchListIds
}

export const getAndSyncWatchlistIds = async () => {
  const templateIds = await getWishList()

  if (templateIds?.length) {
    localStorage.setItem(
      WATCHLIST_LOCALSTORAGE_KEY,
      JSON.stringify(templateIds)
    )

    return Array.from(new Set([...templateIds]).values())
  }

  localStorage.setItem(WATCHLIST_LOCALSTORAGE_KEY, JSON.stringify([]))

  return []
}

export const toggleWatchlistId = async (id: number): Promise<number[]> => {
  const storedIds = await getWishList()

  const index = storedIds.indexOf(id)
  if (index === -1) {
    storedIds.push(id)
  } else {
    storedIds.splice(index, 1)
  }

  upsertWishlist(storedIds)

  localStorage.setItem(WATCHLIST_LOCALSTORAGE_KEY, JSON.stringify(storedIds))
  return storedIds
}
