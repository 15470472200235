export function classNames(...classes: unknown[]): string {
  return classes.filter(Boolean).join(' ')
}

export const removeDuplicateMintMoments = (array: any) =>
  array.filter(
    (set: { name: string }, index: any) =>
      set.name !== 'Mint Moments' ||
      array.findIndex(
        (item: { name: string }) => item.name === 'Mint Moments'
      ) === index
  )

export const sleep = async (duration: number) => {
  return new Promise((resolve) => setTimeout(resolve, duration))
}

export async function generateHash(input: string): Promise<string> {
  const msgUint8 = new TextEncoder().encode(input) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
  return hashHex
}
