import { Grid } from '@mui/material'
import { Dispatch, useState } from 'react'
import skeleton from '/assets/Image/OpenPack/Skeleton3.png'
import { Badges } from 'components/Card/Badges'
import { jerseyBadge } from 'utils/jerseyBadge'
import { CheckCircle } from '@mui/icons-material'

interface SelectionCardProps {
  image: string
  name: string
  player: string
  serial?: number
  tier: string
  badges?: any
  maxSerial?: number
  playersJumperNumber?: number
  isSelected?: boolean
  circulatingSupply?: string
  onClick?: Dispatch<any>
}

export default function SelectionCard(props: SelectionCardProps) {
  const {
    image,
    name,
    player,
    serial,
    tier,
    badges,
    maxSerial,
    playersJumperNumber,
    isSelected,
    circulatingSupply
  } = props

  const isSerialJerseyNumber = playersJumperNumber === serial
  const [isLoaded, setIsLoaded] = useState(false)
  const border = isSelected
    ? 'card-selected'
    : `card-${tier?.toLowerCase() ?? 'common'}`

  return (
    <Grid
      xs={6}
      sm={4}
      md={3}
      lg={2}
      className={`justify-center items-center !p-[6px] xl:!p-3 ${
        playersJumperNumber !== 0 && 'cursor-pointer'
      }`}
      item
    >
      <div className={`relative ${border} !h-max`}>
        <div className={`card-back flex flex-col justify-between`}>
          <div className="aspect-square relative">
            <img
              src={image}
              alt={`${name} NFT`}
              onLoad={() => setIsLoaded(true)}
            />
            <img
              src={skeleton}
              alt=""
              className={`skippers-index-owned absolute top-0 !duration-[1500ms] ${
                !isLoaded ? 'opacity-1 blur-none' : 'opacity-0 blur-lg'
              }`}
            />
            {isSelected && (
              <div className="absolute top-0 right-0 p-1">
                <CheckCircle className="text-green-400" />
              </div>
            )}
          </div>
          <p className="mt-2 mb-1 font-podium49 text-sm tracking-[0.3px] !leading-[15px] uppercase xl:text-lg xl:tracking-wide xl:!leading-[20px] truncate-2">
            {name}
          </p>
          <div className="flex flex-col gap-1 mb-4 text-sm">
            <p className="text-xs font-bold !leading-none text-grey3 xl:text-sm">
              {player}
            </p>
            <div className="justify-between flexCol">
              <div className="flex items-center">
                {tier && (
                  <p
                    className={`font-bold !leading-none text-xs lg:text-sm pr-[6px] ${
                      tier === 'Common' && 'text-grey3'
                    } ${tier === 'Deluxe' && 'text-deluxe'} ${
                      tier === 'Ovation' && 'text-ovation'
                    } ${tier === 'Elite' && 'text-elite'}`}
                  >
                    {tier}
                  </p>
                )}
                {serial && (
                  <p className="text-xs font-normal lg:text-sm">
                    #{serial} {maxSerial && ` / ${maxSerial}`}
                  </p>
                )}
              </div>
              <div className="flex pt-1">
                <>
                  {isSerialJerseyNumber && <Badges badges={[jerseyBadge]} />}
                  <Badges badges={badges} />
                </>
              </div>
              {circulatingSupply && (
                <div className="flex justify-between mt-2 w-full text-xs">
                  <div className="flex items-center">
                    <span>Circulating: {circulatingSupply}</span>
                    <span className="ml-1">🔄</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Grid>
  )
}
