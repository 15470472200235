import * as Sentry from '@sentry/browser'
import { useEffect, useMemo, useState } from 'react'
import * as fcl from '@blocto/fcl'
import { GET_ALL_NFTS_BY_ACCOUNT } from '../flow/scripts/getAllNFTsByAccount'
import {
  GET_LEGACY_LISTINGS_DETAILS_BY_ACCOUNT,
  GET_LISTINGS_DETAILS_BY_ACCOUNT
} from 'flow/scripts/getListingsDetailsByAccount'
import { GET_NFTS_IN_CHUNKS } from '../flow/scripts/getNFTsInChunks'
import { Moment, NFT, Pack, Reward } from 'types'
import { useWallets } from 'providers/WalletsProvider'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
export enum NFTsStatus {
  LOADING,
  DONE,
  ERROR
}
import { duplicateIds, getDuplicateIds } from 'data/duplicates'
import { generateHash } from 'utils'

const cacheData: { [key: string]: any } = {}

export default function useUsersNFTs(walletAddress?: string) {
  const featureFlags = useFeatureFlags()
  const [usersNFTs, setUsersNFTs] = useState<NFT<Moment | Pack | Reward>[]>([])
  const [totalLegacyMarketplaceListings, setTotalLegacyMarketplaceListings] =
    useState<number | undefined>()

  const [status, setStatus] = useState<NFTsStatus>(NFTsStatus.LOADING)
  const { flowUser, isConnected } = useWallets()

  async function getAllMetadata(
    ids: string[],
    listedIds: string[],
    prices?: Record<string, string>
  ) {
    const cacheKey = await generateHash(ids.join(','))
    if (cacheData[cacheKey]) {
      setUsersNFTs(cacheData[cacheKey])
      setStatus(NFTsStatus.DONE)
      return
    }

    const hideIds: string[] =
      import.meta.env.VITE_HIDE_NFT_IDS?.split(',') ?? []

    const _ids = ids.filter((id) => !hideIds.includes(id as string)) as string[]

    try {
      const iNFTs: NFT<Moment | Pack | Reward>[] = []
      const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms))
      const delayBetweenBatches = 1000 // Delay in milliseconds

      for (let i = 0; i < _ids.length; i += 1000) {
        const smallArray = _ids.slice(i, i + 1000)
        const result = await fcl.query({
          cadence: GET_NFTS_IN_CHUNKS,
          args: (arg: any, t: any) => [arg(smallArray, t.Array(t.UInt64))]
        })

        iNFTs.push(...(Object.values(result) as NFT<Moment | Pack | Reward>[]))

        // If there are more batches to process, wait for some time before the next one
        if (i + 1000 < _ids.length) {
          await delay(delayBetweenBatches)
        }
      }
      // add forSale property and price to NFTs in the Sale collection
      const _iNFTs = iNFTs.map((nft) => {
        nft.forSale = listedIds.includes(nft.id)
        if (nft.forSale)
          nft.salePrice = parseFloat(prices?.[nft.id] ?? '0').toFixed(2)
        return nft
      })
      setUsersNFTs(_iNFTs)
      cacheData[cacheKey] = iNFTs
      setStatus(NFTsStatus.DONE)
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
      setStatus(NFTsStatus.ERROR)
    }
  }

  useEffect(() => {
    if (!isConnected) {
      setUsersNFTs([])
      return
    }
    const getAllIds = async () => {
      await getDuplicateIds()
      setStatus(NFTsStatus.LOADING)

      if (!walletAddress && !flowUser?.addr) {
        return []
      }

      try {
        const unlistedMomentIds: string[] = await fcl.query({
          cadence: GET_ALL_NFTS_BY_ACCOUNT,
          args: (arg: any, t: any) => [
            arg(walletAddress || flowUser?.addr, t.Address)
          ]
        })

        if (featureFlags.MARKETPLACE_ACTIVE === true) {
          const listedMomentPricesById = await fcl.query({
            cadence: GET_LISTINGS_DETAILS_BY_ACCOUNT,
            args: (arg: any, t: any) => [
              arg(walletAddress || flowUser?.addr, t.Address)
            ]
          })

          // check for legacy marketplace listings
          const legacyListedMomentPricesById = await fcl.query({
            cadence: GET_LEGACY_LISTINGS_DETAILS_BY_ACCOUNT,
            args: (arg: any, t: any) => [arg(flowUser?.addr, t.Address)]
          })

          setTotalLegacyMarketplaceListings(
            Object.keys(legacyListedMomentPricesById)?.length
          )

          const listedMomentIds = Object.keys(listedMomentPricesById)
          const allMoments = [...unlistedMomentIds, ...listedMomentIds]
          getAllMetadata(allMoments, listedMomentIds, listedMomentPricesById)
        } else {
          getAllMetadata(Object.values(unlistedMomentIds), [])
        }
      } catch (e) {
        if (
          (e as { stack: string }).stack.includes(
            'could not borrow receiver reference'
          )
        ) {
          // soft error
          console.error('address not found or collection not setup')
          return
        } else {
          Sentry.captureException(e)
          console.error(e)
        }
        setStatus(NFTsStatus.ERROR)
      }
    }
    getAllIds()
  }, [walletAddress, flowUser, isConnected])

  // Filter NFTs by type
  // userPacks
  const packs: NFT<Pack>[] = useMemo(() => {
    return usersNFTs.filter(
      (nft) => nft.templateData.immutableData.type === 'pack'
    ) as unknown as NFT<Pack>[]
  }, [usersNFTs])

  // userMoments
  const moments: NFT<Moment>[] = useMemo(() => {
    return (
      usersNFTs.filter(
        (nft) =>
          nft.templateData.immutableData.type === 'moment' ||
          nft.templateData.immutableData.type === 'club-badge'
      ) as unknown as NFT<Moment>[]
    ).map((moment) => {
      // fix metadata
      moment.templateData.immutableData.thumbnail =
        moment.templateData.immutableData.thumbnail.replaceAll('%20', '%2520')
      // add duplicate flag
      moment.isDuplicate = duplicateIds.includes(moment.id) // flag duplicates
      moment.isGenesisBall =
        moment?.templateData.immutableData?.ballColor !== undefined
      return moment
    })
  }, [usersNFTs])

  // userRewards
  const rewards: NFT<Reward>[] = useMemo(() => {
    return usersNFTs.filter(
      (nft) => nft.templateData.immutableData.type === 'reward'
    ) as unknown as NFT<Reward>[]
  }, [usersNFTs])

  return {
    status,
    totalLegacyMarketplaceListings,
    packs,
    moments,
    rewards,
    usersNFTs
  }
}
