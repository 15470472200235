export const CHECK_WUSDC = `
  import FungibleToken from 0xFungibleToken
  import USDCFlow from 0xUSDCFlow

  pub fun main(address: Address): UFix64 {
      let account = getAccount(address)
      let vaultRef = account.getCapability(USDCFlow.VaultPublicPath)
          .borrow<&USDCFlow.Vault{FungibleToken.Balance}>()
          ?? panic("Could not borrow Balance reference to the Vault")

      return vaultRef.balance
  }
`
