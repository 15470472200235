export const DESTROY_COLLECTION = `
  import AFLNFT from 0xAFLContracts

  transaction {
    prepare(acct: AuthAccount) {
      let collection <- acct.load<@AFLNFT.Collection>(from: AFLNFT.CollectionStoragePath)
        ?? panic("No collection found")
      destroy collection
      acct.unlink(AFLNFT.CollectionPublicPath)
    }
  }
`
