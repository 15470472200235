import React from 'react'
import regMark from '/assets/Image/OpenPack/reg-mark.png'
import est from '/assets/Image/OpenPack/established.png'

export const PackBack = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="w-full wrapper-rounded card-glow">
        <div className="flex relative flex-col justify-center items-center wrapper">
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute top-2 left-2 rotate-[-90deg]"
          />
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute top-2 right-2"
          />
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute right-2 bottom-2 rotate-[90deg]"
          />
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute bottom-2 left-2 rotate-180"
          />
          {children}
        </div>
      </div>
    </div>
  )
}

export const PackBack2 = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex justify-center items-center">
      <div className=" wrapper-rounded">
        <div className="flex relative flex-col justify-center items-center wrapper">
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute top-2 left-2 rotate-[-90deg]"
          />
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute top-2 right-2"
          />
          <img
            src={est}
            alt="crop-mark"
            className="absolute top-1/2 left-7 translate-y-[-50%]"
          />
          <img
            src={est}
            alt="crop-mark"
            className="absolute top-1/2 right-7 rotate-180 translate-y-[-50%]"
          />
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute right-2 bottom-2 rotate-[90deg]"
          />
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute bottom-2 left-2 rotate-180"
          />
          {children}
        </div>
      </div>
    </div>
  )
}

export const PackBack3 = ({
  children,
  glow = 'card'
}: {
  children: React.ReactNode
  glow?: string
}) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className={`wrapper-rounded ${glow}-glow`}>
        <div className="flex relative flex-col justify-center items-center !px-2 !pt-10 !pb-12 wrapper">
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute top-2 left-2 rotate-[-90deg]"
          />
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute top-2 right-2"
          />
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute right-2 bottom-2 rotate-[90deg]"
          />
          <img
            src={regMark}
            alt="crop-mark"
            className="absolute bottom-2 left-2 rotate-180"
          />
          {children}
        </div>
      </div>
    </div>
  )
}
