import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import animoca from '/assets/Icon/Animoca.svg'
import afl from '/assets/Icon/AFL.svg'
import aflW from '/assets/Icon/AFLW.svg'
import open from '/assets/Icon/OpenNewWindow.svg'
import discord from '/assets/Icon/Discord.svg'
import twitter from '/assets/Icon/Twitter.svg'
import instagram from '/assets/Icon/Instagram.svg'
import facebook from '/assets/Icon/Facebook.svg'
import youtube from '/assets/Icon/Youtube.svg'

const Footer = () => {
  return (
    <Grid container className="z-50 justify-center w-full bg-black2 footer">
      <div className="flex z-50 flex-col items-center w-full bg-black2">
        <Grid
          item
          container
          justifyContent="center"
          className="z-50 pt-6 pb-10 max-w-[1920px] text-center bg-black2 md:pt-16 lg:pb-16"
        >
          {/* 4 column grid */}
          <Grid
            item
            container
            className="flex !flex-col justify-between px-6 sm:!flex-row  xl:px-28 xsm:px-12"
          >
            {/* 1st Grid */}
            <Grid className="flex flex-col pr-3 pb-6 text-grey3">
              <Grid className="text-left">
                <p className="pb-2 font-podium49 text-sm tracking-widest text-white uppercase">
                  About
                </p>
                <Link to="/learn/about">
                  <p className="pb-2 text-sm ">About AFL Mint</p>
                </Link>
                <Link to="/learn/australian-football-league">
                  <p className="pb-2 text-sm ">About AFL</p>
                </Link>
                <Link to="/roadmap">
                  <p className="pb-2 text-sm ">Roadmap</p>
                </Link>
                <a
                  href="https://www.afl.com.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="flex items-center pb-2 text-sm ">
                    AFL Men&apos;s Competition{' '}
                    <img
                      src={open}
                      alt="open in new tab"
                      className="pl-1 h-[14px] text-center"
                    />
                  </p>
                </a>
                <a
                  href="https://www.womens.afl/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="flex items-center pb-2 text-sm ">
                    AFL Women&apos;s Competition{' '}
                    <img
                      src={open}
                      alt="open in new tab"
                      className="pl-1 h-[14px] text-center"
                    />
                  </p>
                </a>
              </Grid>
            </Grid>
            {/* 2nd Grid */}
            <Grid className="flex flex-col pb-6 text-grey3 sm:px-3">
              <Grid className="text-left">
                <p className="pb-2 font-podium49 text-sm tracking-widest text-white uppercase">
                  Learn & Help
                </p>
                <Link
                  to="/mint-guide"
                  className="flex items-center pb-2 text-sm "
                >
                  Guide to AFL Mint
                </Link>
                <Link to="/learn" className="flex items-center pb-2 text-sm ">
                  Learn
                </Link>
                <a
                  href="https://aflmint.zendesk.com/hc/en-gb/categories/6534884749721-Getting-Started"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="flex items-center pb-2 text-sm ">
                    Getting started
                  </p>
                </a>
                <a
                  href="https://aflmint.zendesk.com/hc/en-gb/articles/7674754080281-How-to-manage-your-Blocto-wallet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="flex items-center pb-2 text-sm ">Wallets</p>
                </a>
                <a
                  href="https://aflmint.zendesk.com/hc/en-gb/categories/6483170136857"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="flex items-center pb-2 text-sm ">
                    Buying and selling collectibles
                  </p>
                </a>
                <a
                  href="https://aflmint.zendesk.com/hc/en-gb/categories/6535059621401"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="flex items-center pb-2 text-sm">
                    Troubleshooting
                  </p>
                </a>
                <a
                  href="https://aflmint.zendesk.com/hc/en-gb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="flex items-center pb-2 text-sm">FAQs & more</p>
                </a>
                <a
                  href="https://aflmint.zendesk.com/hc/en-gb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="flex items-center pb-2 text-sm ">Support</p>
                </a>
                <a
                  href="https://support.aflmint.com.au/hc/en-gb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="flex items-center pb-2 text-sm">Contact us</p>
                </a>
              </Grid>
            </Grid>
            {/* 3rd Grid */}
            <Grid className="flex flex-col pb-6 text-grey3">
              <Grid className="text-left">
                <p className="pb-4 font-podium49 text-sm tracking-widest text-white uppercase">
                  Follow AFL Mint
                </p>
                <Grid
                  item
                  container
                  className="justify-center md:justify-end"
                  columnSpacing={3}
                  xs={12}
                >
                  <Grid item sx={{ py: '5px' }}>
                    <a
                      href="https://discord.com/invite/aflmintofficial"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={discord} alt="" className="social-icon" />
                    </a>
                  </Grid>
                  <Grid item sx={{ py: '5px' }}>
                    <a
                      href="https://twitter.com/aflmint"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitter} alt="" className="social-icon" />
                    </a>
                  </Grid>
                  <Grid item sx={{ py: '5px' }}>
                    <a
                      href="https://www.instagram.com/aflmint/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={instagram} alt="" className="social-icon" />
                    </a>
                  </Grid>
                  <Grid item sx={{ py: '5px' }}>
                    <a
                      href="https://www.youtube.com/channel/UCT1E0Rmm6_DY99dRTFI1hyQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={youtube} alt="" className="social-icon" />
                    </a>
                  </Grid>
                  <Grid item sx={{ py: '5px' }}>
                    <a
                      href="https://facebook.com/aflmint"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={facebook} alt="" className="social-icon" />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className="flex justify-center items-center w-full max-w-[1920px] bg-black2  lg:hidden">
              <div
                className="faq-line"
                style={{ margin: '1.5rem 0rem 2rem', width: '100%' }}
              ></div>
            </div>
            {/* 4th Grid */}
            <Grid className="flex flex-col justify-center">
              <Grid className="flex !flex-col justify-center items-center lg:items-start lg:text-left">
                <Grid className="flex justify-start mb-8">
                  <a
                    href="https://www.afl.com.au/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={afl} alt="AFL Logo" className="h-10" />
                  </a>
                  <a
                    href="https://www.womens.afl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={aflW}
                      alt="AFL Womens League Logo"
                      className="mx-6 h-10"
                    />
                  </a>
                  <a
                    href="https://www.animocabrands.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={animoca} alt="Animoca Logo" className="h-10" />
                  </a>
                </Grid>
                <p className="text-sm lg:w-96">
                  In the spirit of reconciliation the AFL acknowledges the
                  Traditional Custodians of country throughout Australia and
                  their connections to land, sea and community. We pay our
                  respect to their Elders past and present and extend that
                  respect to all Aboriginal and Torres Strait Islander peoples
                  today.
                </p>
                <div className="flex flex-col gap-x-6 items-center md:flex-row">
                  <a href="https://flow.com/" target="_blank" rel="noreferrer">
                    <img
                      style={{ height: '45px' }}
                      className="mt-8"
                      src="/assets/Footer/Flow-Footer.png"
                      alt="Built on Flow"
                    />
                  </a>
                  <a
                    href="https://halborn.com//"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="mt-4 h-[80px] md:mt-8 md:h-[65px]"
                      src="/assets/Footer/audited-by-halborn.png"
                      alt="Halborn logo"
                    />
                  </a>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="flex z-50 justify-center items-center px-10 w-full bg-black2">
          <div
            className="faq-line"
            style={{ margin: '0rem 0rem 2rem', width: '100%' }}
          ></div>
        </div>
        <Grid
          item
          container
          justifyContent="center"
          columnSpacing={{ xs: 100, sm: 100, md: 3 }}
          rowSpacing={{ xs: 1, md: 2 }}
          pb={4}
          className="z-50 pt-2 text-center text-grey3 bg-black2"
        >
          <Grid item className="flex">
            <Link to="/terms-of-use" target="_blank">
              <p className="mr-4 fineprint-text">Terms of Use</p>
            </Link>
            <Link to="/privacy-policy" target="_blank">
              <p className="fineprint-text">Privacy policy</p>
            </Link>
          </Grid>
          <Grid item>
            <p className="fineprint-text">
              Copyright &copy;2023 AFL. All Rights Reserved
            </p>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

export default Footer
