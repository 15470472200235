import * as Sentry from '@sentry/browser'
export const OKTA_TOKE_LOCALSTORAGE_KEY = 'okta-token-storage'

export const getWishList = async () => {
  try {
    const oktaCredentials = JSON.parse(
      localStorage.getItem(OKTA_TOKE_LOCALSTORAGE_KEY) || '{}'
    )

    if (!oktaCredentials?.accessToken?.accessToken) {
      return []
    }

    const raw = await fetch(
      `${import.meta.env.VITE_BACKEND_URL}/api/wishlist`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${oktaCredentials?.accessToken?.accessToken}`
        }
      }
    )
    const response = await raw.json()

    return response?.templateIds ?? []
  } catch (error) {
    Sentry.captureException(error)
    return []
  }
}

export const upsertWishlist = async (ids: number[]) => {
  const oktaCredentials = JSON.parse(
    localStorage.getItem(OKTA_TOKE_LOCALSTORAGE_KEY) || '{}'
  )

  if (!oktaCredentials?.accessToken?.accessToken) {
    return
  }
  const raw = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/wishlist`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${oktaCredentials?.accessToken?.accessToken}`
    },
    body: JSON.stringify({ templateIds: ids })
  })
  const response = await raw.json()

  return response?.templateIds
}
