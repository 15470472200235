import { Dispatch, SetStateAction, useEffect } from 'react'

import { Fade, Modal, Grid } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CustomButton from 'components/Button/CustomButton'
import { Add } from '@mui/icons-material'
import useWrapUSDC, { ExchangeStatus } from 'hooks/useWrapUSDC'
import { useWallets } from 'providers/WalletsProvider'
import usdc from '/assets/Icon/usdc.svg'
import check from '/assets/Icon/CheckIconAnimated.svg'

export interface WUSDCModalProps {
  WUSDCModalOpen: boolean
  setWUSDCModalOpen: Dispatch<SetStateAction<boolean>>
}

const WUSDCModal = ({ WUSDCModalOpen, setWUSDCModalOpen }: WUSDCModalProps) => {
  const handleClose = () => setWUSDCModalOpen(false)
  const { wrapUSDC, status } = useWrapUSDC()
  const { flowUSDCBalance, refreshWallets } = useWallets()

  useEffect(() => {
    if (status === ExchangeStatus.DONE) {
      setTimeout(() => {
        refreshWallets()
        handleClose()
      }, 5000)
    }
  }, [status])

  return (
    <Modal
      open={WUSDCModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className="overflow-auto modal-bg"
    >
      <Fade in={WUSDCModalOpen}>
        <div className="modal-container">
          <div className="min-w-[300px]">
            <div className="flex justify-between">
              <div
                className="flex justify-end items-end w-full cursor-pointer"
                onClick={() => handleClose()}
              >
                <Add
                  style={{
                    transform: `rotate(45deg)`,
                    color: '#ffffff',
                    fontSize: '28px'
                  }}
                />
              </div>
            </div>
            <div className="bg-black rounded-lg">
              {status === ExchangeStatus.DONE ? (
                <div>
                  <div className="pb-6 sm:pb-8 flexCenter">
                    <div className="aspect-square w-20 bg-green-500 rounded-full flexCenter">
                      <img src={check} alt="usdc icon" className="w-3/5" />
                    </div>
                  </div>
                  <div className="pb-4 text-lg font-bold">
                    <p>
                      You have{' '}
                      <span className="text-green-500">successfully</span>{' '}
                      wrapped your USDC to WUSDC.
                    </p>
                  </div>
                  <p className="pb-3 text-sm">
                    You can now use your Wrapped USDC (WUSDC) in AFL Mint.
                  </p>
                </div>
              ) : (
                <>
                  <div className="flexCenter">
                    <img
                      src={usdc}
                      alt="usdc icon"
                      className="pb-6 w-20 sm:pb-8 sm:w-24"
                    />
                  </div>
                  <div className="pb-4 text-lg font-bold">
                    <p>
                      You have{' '}
                      <span className="text-green-500">
                        {flowUSDCBalance.toFixed(2)}
                      </span>{' '}
                      USD Coin (USDC) in your wallet.
                    </p>
                  </div>
                  <p className="pb-3 text-sm">
                    Starting August 20th, 2024, AFL Mint will only support
                    Wrapped USDC (WUSDC). Please swap your USDC to WUSDC to
                    continue using AFL Mint. <br /> Here&apos;s the scoop on
                    WUSDC:
                  </p>
                  <ul className="pb-3 text-sm">
                    <li>• It works everywhere in the Web3 universe</li>
                    <li>• Same $1.00 value, fully backed and safe </li>
                    <li>• Ready for withdrawing directly to exchanges</li>
                  </ul>
                  <div className="pb-8 text-sm">
                    We’ve made this easy for you! Just click the button below,
                    and we’ll take care of converting your USDC to WUSDC.
                  </div>
                  <Grid
                    item
                    className="flex flex-row justify-end items-center w-full"
                  >
                    <CustomButton
                      type="button"
                      model="primary"
                      variant="contained"
                      label="Wrap your USDC"
                      className="w-full"
                      onClick={() => wrapUSDC()}
                      disabled={status !== ExchangeStatus.IDLE}
                    />
                  </Grid>
                </>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default WUSDCModal
