export const GET_ALL_NFTS_BY_ACCOUNT = `
  import AFLNFT from 0xAFLContracts

  pub fun main(addr:Address): AnyStruct {
    let account = getAccount(addr)
    let acctCapability =  account.getCapability(AFLNFT.CollectionPublicPath)
                            .borrow<&{AFLNFT.AFLNFTCollectionPublic}>()
                            ??panic("could not borrow receiver reference ")
    let nftIds =  acctCapability.getIDs()
    return nftIds
  }
`
