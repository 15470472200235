import { m } from 'framer-motion'

export function dateTransform(date: string, addTime = false) {
  const dateObject = new Date(date)

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short'
  }

  if (addTime) {
    options.hour = 'numeric'
    options.minute = 'numeric'
  }

  return dateObject.toLocaleDateString('en-AU', options)
}

export function dateParse(date: string) {
  const dateObject = new Date(`${date}T00:00:00`)

  return dateObject.toLocaleDateString('en-AU', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}
