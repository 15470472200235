import { marketplaceEarlyAccessUsers } from '../utils/earlyAccessList'
import { useWallets } from 'providers/WalletsProvider'

export const useFeatureFlags = () => {
  const { flowUser } = useWallets()

  const USE_MAGIC_WALLET = import.meta.env.VITE_USE_MAGICWALLET === 'true'

  const MARKETPLACE_ACTIVE = import.meta.env.VITE_ENABLE_MARKETPLACE === 'true'

  const PRIZE_DRAW_ACTIVE = import.meta.env.VITE_ENABLE_PRIZE_DRAW === 'true'

  const MARKETPLACE_PRODUCTINFO_POPUP_ACTIVE =
    import.meta.env.VITE_ENABLE_MARKETPLACE_INFO_POPUP === 'true'

  const MARKETPLACE_FEE = parseFloat(
    import.meta.env.VITE_MARKETPLACE_FEE ?? 0.08
  )

  const DUPLICATES_SWAP_ENABLED =
    import.meta.env.VITE_ENABLE_DUPLICATES_SWAP === 'true'

  return {
    USE_MAGIC_WALLET,
    MARKETPLACE_ACTIVE,
    MARKETPLACE_FEE,
    MARKETPLACE_PRODUCTINFO_POPUP_ACTIVE,
    DUPLICATES_SWAP_ENABLED,
    PRIZE_DRAW_ACTIVE
  }
}
