import React, { useEffect, useState } from 'react'
import maintenance from '/assets/Image/maintenance-page-bg-bw.jpg'
import logo from '/assets/logo/Mint-logo.png'
import CountdownTimer from 'components/Counter/CountdownTimer'
import Socials from 'components/Socials/Socials'
import { getSiteSettings } from 'services/api.service'
import { SiteSettingsProps } from 'types'

const MaintenancePage = () => {
  const [siteSettings, setSiteSettings] = useState<SiteSettingsProps | null>(
    null
  )

  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const siteSettings: SiteSettingsProps = await getSiteSettings()
        setSiteSettings(siteSettings)
      } catch (error) {
        console.error('Failed to fetch site settings:', error)
        setSiteSettings(null) // or set a default value
      }
    }

    fetchMaintenanceData()
  }, [])

  const backgroundImage = `url('${maintenance}')`

  return (
    <div
      className={`bg-no-repeat bg-cover text-center bg-center h-screen global-padding flexColCenter  gap-4  text-white`}
      style={{
        backgroundImage: backgroundImage
      }}
    >
      <img src={logo} alt="AFL Mint Logo" className="w-48 md:w-60" />
      <p className="font-podium49 text-[26px] font-bold uppercase md:py-2 md:text-5xl">
        {siteSettings?.siteMaintenanceModeTitle}
      </p>
      <p className="pb-4 md:text-xl">
        {siteSettings?.siteMaintenanceModeMessage}
      </p>
      <CountdownTimer
        countdownDate={import.meta.env.VITE_COUNTDOWN_MAINTENANCE_TIME}
        countdownLocation="maintenance"
      />
      <div className="pt-4 flexColCenter">
        <p className="pb-2 text-xs md:text-sm">
          For more information, please visit our socials
        </p>
        <Socials />
      </div>
    </div>
  )
}

export default MaintenancePage
