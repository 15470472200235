import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import Footer from 'layout/Footer'
import useUsersNFTs, { NFTsStatus } from 'hooks/useUsersNFTs'
import ItemCard from 'components/TrophyCabinet/ItemCard'
import { dateTransform } from 'utils/dateTransform'
import CustomButton from 'components/Button/CustomButton'
import transfer from '/assets/Icon/Transfer.svg'
import FaqSection from 'components/ContentBlock/FaqSection'
import useBurnExchange, { TransactionStatus } from 'hooks/useBurnExchange'
import useMoments from 'hooks/useMoments'
import { useNavigate } from 'react-router-dom'
import skeleton from '/assets/Image/OpenPack/Skeleton3.png'
import Spinner from 'components/Transactions/Spinner'

const DuplicatesTradePage = () => {
  const { walletAddress } = useParams()
  const { moments, status } = useUsersNFTs(walletAddress)
  const duplicates = moments?.filter(
    (moment) => moment.isDuplicate && !moment.forSale
  )
  const [displayCount, setDisplayCount] = React.useState(12)
  const navigate = useNavigate()

  const duplicatesArray = React.useMemo(() => {
    return duplicates.map((momentToSwap) => {
      return {
        ...momentToSwap,
        floorPrice: momentToSwap.floorPrice,
        ...momentToSwap.templateData.immutableData,
        id: momentToSwap.id,
        mintNumber: momentToSwap.mintNumber,
        templateId: momentToSwap.templateData.templateId
      }
    })
  }, [moments])

  const momentsToDisplay = duplicatesArray.slice(0, displayCount)

  const handleLoadMore = () => {
    setDisplayCount(displayCount + 200)
  }

  const idsToSwap: number[] = duplicatesArray.map((momentToSwap: any) =>
    parseInt(momentToSwap.id)
  )

  const { idsAvailable, swapAll, txStatus } = useBurnExchange(
    idsToSwap.map((id) => id.toString())
  )

  const { moments: _exchangedMoments } =
    useMoments(
      idsAvailable.map((id) => id.toString()).filter((id) => id != '0') ?? []
    ) ?? []

  const exchangedMoments = idsAvailable.map((id) => {
    return (
      _exchangedMoments && _exchangedMoments.find((v) => v.id === id.toString())
    )
  })

  const allSwapsNotYetAvailable =
    idsAvailable?.every((swapId) => swapId === '0') && idsToSwap.length > 0

  // const correctOrder = exchangedMoments?.sort((a, b) => {
  //   const indexA = idsAvailable.indexOf(a.id)
  //   const indexB = idsAvailable.indexOf(b.id)
  //   return indexA - indexB
  // })

  return (
    <div className="flex flex-col justify-center items-center text-white bg-black">
      <Grid container className="flex flex-row justify-center">
        <Grid className="pt-20 w-full md:pt-36" item>
          <div className="flex flex-col items-center bg-black">
            <div className="flex gap-2 justify-center pt-4 w-full">
              <div className="flex flex-col gap-4 items-center w-full md:max-w-[900px] global-padding">
                <div className="w-full font-podium49 text-5xl leading-[50px] text-center  text-white uppercase">
                  {txStatus === TransactionStatus.SUCCESS
                    ? 'Trade complete'
                    : 'Trade your duplicate moments'}
                </div>
                {txStatus === TransactionStatus.SUCCESS ? (
                  <div className="flex flex-col gap-y-3 items-center">
                    <div className="text-lg">
                      All moments succesfully traded.
                    </div>
                    <div className="text-lg">
                      To view your updated moments, sort by &apos;Newest
                      Received&apos; in your Trophy Cabinet.
                    </div>
                    <CustomButton
                      type="button"
                      model="primary"
                      variant="contained"
                      label={`View in Trophy Cabinet`}
                      className="!text-white"
                      onClick={() => navigate('/trophy-cabinet')}
                    />
                  </div>
                ) : (
                  <div className="w-full text-lg text-center text-white ">
                    Due to a minting error some moments were minted more than
                    once. The moments from your collection below were the second
                    to be minted and are blocked from being listed on the
                    Marketplace. You can now exchange these for moments as
                    displayed below.{' '}
                    {idsToSwap &&
                      idsToSwap?.length > 99 &&
                      'You can only exchange 100 moments per tx, please repeat the process until all moments are exchanged.'}
                    <a
                      href="#faq"
                      className="underline hover:no-underline transition-all"
                    >
                      Learn more.
                    </a>
                  </div>
                )}
                {status === NFTsStatus.LOADING && <Spinner />}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center py-6 w-full h-[100px]">
            {idsAvailable.filter((id) => id !== '0')?.length > 0 &&
              txStatus != TransactionStatus.SUCCESS && (
                <CustomButton
                  type="button"
                  model={
                    txStatus === TransactionStatus.INFLIGHT
                      ? 'disabled'
                      : 'primary'
                  }
                  variant="contained"
                  label={`Trade your moments now`}
                  className="!text-white"
                  disabled={
                    txStatus == TransactionStatus.INFLIGHT ||
                    idsAvailable.length === 0
                  }
                  onClick={() => swapAll(idsToSwap.splice(0, 100))}
                />
              )}
            {allSwapsNotYetAvailable && (
              <div>
                <div className="text-lg text-center text-white ">
                  Check back in a day or two to exchange your duplicate/s.
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <div className="flex flex-col justify-center items-center mt-20 w-full max-w-[1920px] global-padding">
        {txStatus !== TransactionStatus.SUCCESS && (
          <div className="w-full">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {momentsToDisplay?.map((momentToSwap: any, i: number) => {
                const swap = exchangedMoments && exchangedMoments[i]
                return (
                  <div key={i} className="flex relative w-full">
                    <ItemCard
                      link={`/moment/${momentToSwap.id}`}
                      image={momentToSwap.templateData.immutableData.thumbnail?.replaceAll(
                        '%20',
                        '%2520'
                      )}
                      name={momentToSwap.templateData.immutableData.headline}
                      player={momentToSwap.templateData.immutableData.player}
                      date={dateTransform(
                        momentToSwap.templateData.immutableData.date
                      )}
                      serial={momentToSwap.mintNumber || 'Reward'}
                      playersJumperNumber={
                        momentToSwap.templateData.immutableData
                          ?.playersJumperNumber
                      }
                      type="nft"
                      tier={momentToSwap.templateData.immutableData.tier}
                      isDuplicate={momentToSwap?.isDuplicate}
                      maxSerial={
                        momentToSwap.templateData.immutableData?.maxSerial
                      }
                      showWatchlistBtn={false}
                    />
                    <div className="flex absolute top-1/2 left-1/2 z-10 justify-center p-2 w-10 h-10 bg-white rounded-[50%] translate-x-[-50%] translate-y-[-50%]">
                      <img src={transfer} alt="Transfer" className="" />
                    </div>
                    {swap?.id != '0' ? (
                      <ItemCard
                        link={`${swap?.id}`}
                        image={
                          swap?.templateData.immutableData.thumbnail?.replaceAll(
                            '%20',
                            '%2520'
                          ) ?? ''
                        }
                        name={swap?.templateData.immutableData.headline ?? ''}
                        player={swap?.templateData.immutableData.player}
                        date={dateTransform(
                          swap?.templateData.immutableData.date ?? ''
                        )}
                        serial={swap?.mintNumber || 'Reward'}
                        playersJumperNumber={
                          momentToSwap.templateData.immutableData
                            ?.playersJumperNumber
                        }
                        type="nft"
                        tier={swap?.templateData.immutableData.tier}
                        isDuplicate={swap?.isDuplicate}
                        maxSerial={swap?.templateData.immutableData?.maxSerial}
                        isNew={true}
                      />
                    ) : (
                      <ItemCard
                        link={`#`}
                        image={skeleton}
                        name={'No swap available yet'}
                        date={''}
                        serial={'?'}
                        type="nft"
                        isNew={true}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        {displayCount < duplicatesArray.length &&
          txStatus != TransactionStatus.SUCCESS && (
            <div className="flex justify-center pt-10 w-full">
              <CustomButton
                type="button"
                model="secondary"
                variant="contained"
                label={`See all`}
                className="!text-white"
                onClick={() => {
                  handleLoadMore()
                }}
              />
            </div>
          )}
        <section id="faq" className="pt-14 w-full">
          <FaqSection header="FAQs" faqName="momentExchangePage" />
        </section>
      </div>
      <Grid container className="bg-black">
        <Grid item sx={{ width: '100%' }}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  )
}

export default DuplicatesTradePage
