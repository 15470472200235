import * as Sentry from '@sentry/browser'
import {
  ActiveListing,
  ActiveListingParams,
  SaleCollection,
  SaleHistory,
  SaleHistoryParams
} from 'types'

// Marketplace API
export const listAllSaleCollections = async (params: {
  limit?: string
  offset?: string
}): Promise<SaleCollection[]> => {
  try {
    const queryString = new URLSearchParams(params).toString()
    const raw = await fetch(
      `${
        import.meta.env.VITE_BACKEND_URL
      }/api/marketplace/sale-collections?${queryString}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )
    const response = await raw.json()
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return []
  }
}

export const getActiveListings = async (
  params: ActiveListingParams
): Promise<ActiveListing[]> => {
  if (params.template_id == undefined) return []
  try {
    const queryString = new URLSearchParams(params)
    const raw = await fetch(
      `${
        import.meta.env.VITE_BACKEND_URL
      }/api/marketplace/sale-nfts?${queryString}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )
    const response = await raw.json()
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return []
  }
}

export const getSaleHistory = async (
  params: SaleHistoryParams
): Promise<{ totalCount: number; data: SaleHistory[] }> => {
  try {
    const queryString = new URLSearchParams(params).toString()
    const raw = await fetch(
      `${
        import.meta.env.VITE_BACKEND_URL
      }/api/marketplace/sale-nft-histories?${queryString}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )
    const response = await raw.json()
    return response
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return { totalCount: 0, data: [] }
  }
}

export const getLowestPrices = async (params: any): Promise<any[]> => {
  try {
    const queryString = new URLSearchParams(params).toString()
    const raw = await fetch(
      `${
        import.meta.env.VITE_BACKEND_URL
      }/api/marketplace/sale-nfts/lowest-price?${queryString}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )
    const response = await raw.json()
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return []
  }
}

interface GetForSaleNFTsByTemplateIdsParams {
  templateIds: number[]
  limit?: string
  offset?: string
}

export const getForSaleNFTsByTemplateIds = async (
  params: GetForSaleNFTsByTemplateIdsParams
): Promise<any> => {
  try {
    const queryString = new URLSearchParams({
      templateIds: params.templateIds.join(','),
      ...(params.limit && { limit: params.limit }),
      ...(params.offset && { offset: params.offset })
    })
    const raw = await fetch(
      `${
        import.meta.env.VITE_BACKEND_URL
      }/api/marketplace/sale-nfts-by-templateIds?${queryString}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )
    const response = await raw.json()
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return []
  }
}

export const getSaleHistoryByTemplateIds = async (
  params: any
): Promise<any[]> => {
  try {
    const queryString = new URLSearchParams(params)
    const raw = await fetch(
      `${
        import.meta.env.VITE_BACKEND_URL
      }/api/marketplace/sale-nft-histories-by-templateIds?${queryString}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )
    const response = await raw.json()
    return response.data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return []
  }
}
