import React, { useEffect } from 'react'
import CustomButton from 'components/Button/CustomButton'
import useExchangeRate from 'hooks/useExchangeRate'
import wallet from '/assets/Icon/WalletOutline.svg'
import { useWallets } from 'providers/WalletsProvider'
import MoonpayModal from 'components/Modal/MoonpayModal'
import usePack from 'hooks/usePack'
import { PackStatus } from 'hooks/usePack'
import { useSnackbar } from 'hooks/useSnackbar'
import { useNavigate } from 'react-router-dom'

interface WalletPaymentCardProps {
  packPrice?: string
  packId?: string
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  setCheckoutType: React.Dispatch<React.SetStateAction<'nft' | 'funds'>>
  closePaymentModal: () => void
}

function WalletPaymentCard({
  packPrice,
  packId,
  setActiveStep,
  setCheckoutType,
  closePaymentModal
}: WalletPaymentCardProps) {
  const { flowWUSDCBalance } = useWallets()
  const { rate } = useExchangeRate()
  const { formatAUDPrice } = useExchangeRate()
  const USDCBalance = flowWUSDCBalance.toFixed(2)
  const [moonpayModal, showMoonpayModal] = React.useState(false)
  const hasEnoughFunds = Number(USDCBalance) * rate >= Number(packPrice)
  const { buyPack, status, errorMessage } = usePack(parseInt(packId || ''))
  const { openSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    switch (status) {
      case PackStatus.IDLE:
        break
      case PackStatus.DONE_BUY:
        openSnackbar('Pack purchased succesfully')
        // after 2 seconds redirect to trophy cabinet
        setTimeout(() => {
          closePaymentModal()
          navigate(`/trophy-cabinet?section=packs`, {
            state: { purchaseSuccess: true }
          })
        }, 2000)
        break
      case PackStatus.LOADING:
        break
      case PackStatus.BUYING:
        closePaymentModal()
        break
      case PackStatus.DONE:
        break
      case PackStatus.ERROR:
        console.error('pack error', errorMessage)
        openSnackbar('Error purchasing pack')
        break
    }
  }, [status])

  const addFunds = async () => {
    setCheckoutType('funds')
    setActiveStep(2)
  }

  const closeMoonpayModal = () => {
    showMoonpayModal(false)
  }

  const handleBuyNowClick = () => {
    buyPack()
  }

  return (
    <div className="flex flex-col gap-2 justify-between p-4 w-full bg-card rounded-[3px]">
      {packId && (
        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-3 justify-between items-start w-full">
            <div className="flex flex-col gap-2 justify-center items-start w-full">
              <div className="flex justify-between items-center w-full font-bold">
                <div className="flex gap-2 items-center">
                  <img src={wallet} alt={`Wallet icon`} className="w-6 h-6" />
                  <div className="text-white">Wallet funds</div>
                </div>
                <p>A${packPrice}</p>
              </div>
              <p className="text-xs">Instant • No fees</p>
            </div>
          </div>

          <div className="flex flex-col gap-2 items-start py-2 w-full border-t border-[#3e3e3e]">
            <div className="flex gap-2 justify-between items-start w-full">
              <div className="flex flex-col items-start">
                <div className="text-xs">My balance</div>
              </div>
              <div className="flex flex-col items-end text-sm text-right">
                <div className="text-xs">
                  A${formatAUDPrice(flowWUSDCBalance)}
                </div>
                <div className="text-xs text-grey2">({USDCBalance} USDC)</div>
              </div>
            </div>
          </div>
          {!hasEnoughFunds ? (
            <CustomButton
              label="Add funds"
              model="primary"
              variant="contained"
              onClick={() => addFunds()}
            />
          ) : (
            <CustomButton
              onClick={handleBuyNowClick}
              label="Buy now"
              model="primary"
              variant="contained"
            />
          )}
        </div>
      )}
      <MoonpayModal
        open={moonpayModal}
        onClose={closeMoonpayModal}
        mode="funds"
      />
    </div>
  )
}
export default WalletPaymentCard
