import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import Footer from 'layout/Footer'
import useUsersNFTs, { NFTsStatus } from 'hooks/useUsersNFTs'
import CustomButton from 'components/Button/CustomButton'
import FaqSection from 'components/ContentBlock/FaqSection'
import Spinner from 'components/Transactions/Spinner'
import { useWallets } from 'providers/WalletsProvider'
import useGenericMoment from 'hooks/useGenericMoment'
import { Moment, NFT } from 'types'
import SelectionCard from 'components/TrophyCabinet/SelectionCard'
import useUpdateMoments, { ExchangeStatus } from 'hooks/useUpgradeMoments'
import OldToNewMomentSection from './ui/OldToNewMomentSection'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { ExpandMoreRounded } from '@mui/icons-material'

const RipperSkippersTradePage = () => {
  const { momentId } = useParams()
  const { moment } = useGenericMoment(momentId)
  const { flowUser } = useWallets()
  const navigate = useNavigate()
  const { moments, status } = useUsersNFTs(flowUser?.addr)
  const {
    updateMoments,
    upgradePaths,
    upgradeRequirements,
    status: txStatus
  } = useUpdateMoments(momentId)
  const [selectedSerialNftIds, setSelectedSerialNftIds] = useState<string[]>([])
  const [upgradeToTemplateId, setUpgradeToTemplateId] = useState<string>()
  const [selectedBurnMomentIds, setSelectedBurnMomentIds] = useState<string[]>(
    []
  )

  const { moment: upgradedMoment } = useGenericMoment(upgradeToTemplateId)

  useEffect(() => {
    if (momentId) setUpgradeToTemplateId(upgradePaths[momentId])
  }, [upgradePaths, momentId])

  const baseMoments = moments?.filter(
    (m) => m.templateData.templateId === momentId
  )

  const calculateRequirements = (upgradeReqs: any[], selectedCount: number) => {
    return upgradeReqs.map((req, index) => ({
      id: index,
      quantity: parseInt(req.quantity) * selectedCount,
      templateIds: req.templateIds,
      selected: 0
    }))
  }

  const requirements = calculateRequirements(
    upgradeRequirements || [],
    selectedSerialNftIds.length
  )

  const burnMoments = moments?.filter((moment) => {
    if (moment.forSale) return false
    if (!upgradeRequirements) return false
    return upgradeRequirements.some((requirement: any) =>
      requirement.templateIds.includes(moment.templateData.templateId)
    ) // && moment.templateData.templateId !== momentId;
  })

  const renderTier = (tier: string) => {
    return <span className={`text-${tier.toLowerCase()}`}>{tier}</span>
  }

  // Group burn moments by requirement
  const groupedBurnMoments = requirements.map((req) => ({
    ...req,
    tier: renderTier(
      burnMoments?.find((moment) =>
        req.templateIds.includes(moment.templateData?.templateId)
      )?.templateData?.immutableData?.tier ?? ''
    ),
    moments:
      burnMoments?.filter((moment) =>
        req.templateIds.includes(moment.templateData?.templateId)
      ) || []
  }))

  // Update the selected count for each requirement group
  selectedBurnMomentIds.forEach((id) => {
    const moment = burnMoments?.find((m) => m.id === id)
    if (moment) {
      const reqGroup = groupedBurnMoments.find((group) =>
        group.templateIds.includes(moment.templateData.templateId)
      )
      if (reqGroup) {
        reqGroup.selected++
      }
    }
  })

  const handleSelectionClick = (momentToBurn: NFT<Moment>) => {
    setSelectedBurnMomentIds((prevIds) => {
      if (prevIds.includes(momentToBurn.id)) {
        return prevIds.filter((id) => id !== momentToBurn.id)
      } else {
        const reqGroup = groupedBurnMoments.find((group) =>
          group.templateIds.includes(momentToBurn.templateData.templateId)
        )
        if (reqGroup && reqGroup.selected < reqGroup.quantity) {
          return [...prevIds, momentToBurn.id]
        }
      }
      return prevIds
    })
  }

  const handleSerialChange = (event: { target: { value: any } }) => {
    const value = event.target.value
    setSelectedSerialNftIds((prevSelected) => {
      if (prevSelected.includes(value)) {
        // User is unchecking a serial
        setSelectedBurnMomentIds([]) // Clear burn selection
        return prevSelected.filter((serial) => serial !== value)
      } else {
        // User is checking a serial
        return [...prevSelected, value]
      }
    })
  }

  const exchangeButtonClicked = () => {
    if (selectedBurnMomentIds.length > 0) {
      updateMoments(selectedSerialNftIds, selectedBurnMomentIds)
    }
  }

  const allGroupRequirementsMet = () => {
    return groupedBurnMoments.every((group) => group.selected >= group.quantity)
  }

  return (
    <div className="flex flex-col justify-center items-center text-white bg-black">
      <Grid container className="flex flex-row justify-center pb-10">
        <Grid className="pt-20 w-full md:pt-36" item>
          <div className="flex flex-col items-center bg-black">
            <div className="flex gap-2 justify-center pt-4 w-full">
              <div className="flex flex-col gap-6 items-center w-full md:max-w-[900px] global-padding">
                <div className="w-full font-podium49 text-4xl leading-[50px] text-center text-white uppercase md:text-5xl">
                  {txStatus === ExchangeStatus.DONE
                    ? 'Trade complete'
                    : 'Sign your moment/s'}
                </div>
                {txStatus === ExchangeStatus.DONE ? (
                  <div className="flex flex-col gap-y-3 items-center">
                    <div className="text-lg">
                      All moments successfully exchanged.
                    </div>
                    <div className="text-lg">
                      To view your updated moment, sort by &apos;Newest
                      Received&apos; in your Trophy Cabinet.
                    </div>
                    <CustomButton
                      type="button"
                      model="primary"
                      variant="contained"
                      label="View in Trophy Cabinet"
                      className="!text-white"
                      onClick={() => navigate('/trophy-cabinet')}
                    />
                  </div>
                ) : (
                  <div className="w-full text-center text-white sm:text-lg">
                    Exchange previous Ripper Skipper moments to upgrade your new
                    moments to the signed version!
                  </div>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="gap-8 justify-center items-center w-full smd:pb-4 flexCol global-padding">
        <div className="hidden w-2/3 md:block lg:w-1/2 xl:w-1/3">
          <Accordion
            sx={{
              backgroundColor: '#070707',
              color: '#fff',
              borderRadius: '3px'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreRounded className="text-white" />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p className="font-podium49 text-xl underline uppercase md:text-2xl">
                How it works?
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="col-span-2 flexCol">
                <ul className="gap-3 pl-5 list-disc flexCol">
                  <li className="sm:text-lg">
                    Select the serials of the moment you would like to upgrade.
                    You can upgrade multiple serials at once.
                  </li>
                  <li className="sm:text-lg">
                    The list of required moments to burn will be displayed and
                    updated as you select serials to upgrade.
                  </li>
                  <li className="sm:text-lg">
                    Select the moments you would like to burn to upgrade your
                    selected serials.
                  </li>
                </ul>
                <div className="pt-4 mt-6 border-t-[1px] border-grey1">
                  <p className="font-bold underline sm:text-lg">
                    Burn Requirements per Moment
                  </p>
                  <ul className="gap-1 py-3 pl-5 text-sm list-disc sm:text-base flexCol">
                    <li className="">
                      <span className="text-grey3">Common</span> -{'>'} Burn any
                      2 previous Ripper Skipper Common moments and the 2024
                      Common moment for a{' '}
                      <span className="text-white">Common</span> upgrade.
                    </li>
                    <li className="">
                      <span className="text-deluxe">Deluxe</span> -{'>'} Burn
                      any 1 previous Ripper Skipper Deluxe, 1 previous Ripper
                      Skipper Common and a 2024 Deluxe for a{' '}
                      <span className="text-deluxe">Deluxe </span>
                      upgrade.
                    </li>
                    <li className="">
                      <span className="text-ovation">Ovation</span> -{'>'} Burn
                      any 1 previous Ripper Skipper Deluxe, 5 previous Ripper
                      Skipper Common moments and a 2024 Ovation for an{' '}
                      <span className="text-ovation">Ovation</span> upgrade.
                    </li>
                  </ul>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <OldToNewMomentSection oldMoment={moment} newMoment={upgradedMoment} />
      </div>
      <div className="flex flex-col justify-center items-center mt-10 w-full max-w-[1920px] global-padding">
        {txStatus !== ExchangeStatus.DONE && (
          <div className="flex relative">
            <div className="gap-16 flexCol">
              <div className="gap-6 flexCol">
                <div className="">
                  <div className="md:hidden">
                    <Accordion
                      defaultExpanded
                      sx={{
                        backgroundColor: '#070707',
                        color: '#fff',
                        borderRadius: '3px'
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreRounded className="text-white" />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <p className="font-podium49 text-xl underline uppercase md:text-2xl">
                          How it works?
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="col-span-2 flexCol">
                          <ul className="gap-3 pl-5 list-disc flexCol">
                            <li className="sm:text-lg">
                              Select the serials of the moment you would like to
                              upgrade. You can upgrade multiple serials at once.
                            </li>
                            <li className="sm:text-lg">
                              The list of required moments to burn will be
                              displayed and updated as you select serials to
                              upgrade.
                            </li>
                            <li className="sm:text-lg">
                              Select the moments you would like to burn to
                              upgrade your selected serials.
                            </li>
                          </ul>
                          <div className="pt-4 mt-6 border-t-[1px] border-grey1">
                            <p className="font-bold underline sm:text-lg">
                              Burn Requirements per Moment
                            </p>
                            <ul className="gap-1 py-3 pl-5 text-sm list-disc sm:text-base flexCol">
                              <li className="">
                                <span className="text-grey3">Common</span> -
                                {'>'} Burn any 2 previous Ripper Skipper Common
                                moments and the 2024 Common moment for a{' '}
                                <span className="text-white">Common</span>{' '}
                                upgrade.
                              </li>
                              <li className="">
                                <span className="text-deluxe">Deluxe</span> -
                                {'>'} Burn any 1 previous Ripper Skipper Deluxe,
                                1 previous Ripper Skipper Common and a 2024
                                Deluxe for a{' '}
                                <span className="text-deluxe">Deluxe </span>
                                upgrade.
                              </li>
                              <li className="">
                                <span className="text-ovation">Ovation</span> -
                                {'>'} Burn any 1 previous Ripper Skipper Deluxe,
                                5 previous Ripper Skipper Common moments and a
                                2024 Ovation for an{' '}
                                <span className="text-ovation">Ovation</span>{' '}
                                upgrade.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="justify-between pt-10 flexCol">
                    <div className="flex justify-between items-center">
                      <h4 className="font-podium49 text-2xl uppercase md:text-3xl">
                        Step 1: Select Your moment serials to upgrade
                      </h4>
                    </div>
                    <div className="py-4 flexCol">
                      <p className="text-lg">
                        Available serials{' '}
                        <span className="text-sm">(select at least 1)</span>
                      </p>
                      <div className="flex flex-wrap gap-x-8 gap-y-4 items-center">
                        {baseMoments
                          .sort(
                            (a, b) =>
                              parseFloat(a.mintNumber) -
                              parseFloat(b.mintNumber)
                          )
                          .map((m) => (
                            <div key={m.id} className="flex items-center">
                              <input
                                type="checkbox"
                                value={m.id}
                                checked={selectedSerialNftIds.includes(m.id)}
                                onChange={handleSerialChange}
                                className="mr-2 filterCheckBox"
                              />
                              <label className="pt-[6px] text-xl leading-none">
                                {m.mintNumber}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="gap-6 flexCol">
                <div className="justify-between lg:flex-row flexCol">
                  <h4 className="pb-3 font-podium49 text-2xl uppercase md:text-3xl lg:pb-0">
                    Step 2: Select Your moments to burn
                  </h4>
                  <div className="gap-4 sm:flex-row lg:gap-6 flexCol">
                    <CustomButton
                      type="button"
                      model="secondary"
                      variant="contained"
                      label="Clear Selection"
                      className="!text-white whitespace-nowrap"
                      disabled={
                        txStatus == ExchangeStatus.EXCHANGING ||
                        selectedBurnMomentIds.length === 0
                      }
                      onClick={() => setSelectedBurnMomentIds([])}
                    />
                    <CustomButton
                      type="button"
                      model="primary"
                      variant="contained"
                      label="Exchange your moments now"
                      className="!text-white whitespace-nowrap"
                      disabled={
                        txStatus == ExchangeStatus.EXCHANGING ||
                        !allGroupRequirementsMet() ||
                        selectedSerialNftIds.length === 0
                      }
                      onClick={exchangeButtonClicked}
                    />
                  </div>
                </div>
                <div className="relative card-legend">
                  <div className="absolute top-[1px] right-[1px] z-50 gap-2 items-center py-2 px-4 bg-black/95 rounded-[10px] flexCol">
                    {groupedBurnMoments.map((group, i) => (
                      <p key={i} className="py-1 text-sm font-bold">
                        <span
                          className={
                            group.selected >= group.quantity
                              ? 'text-green-500'
                              : 'text-red-500'
                          }
                        >
                          {group.selected} / {group.quantity}
                        </span>{' '}
                        {group.tier} moments
                      </p>
                    ))}
                  </div>
                  <div className="overflow-scroll w-full max-h-[50dvh] rounded-md card-back ripper-skippers-moments">
                    {status === NFTsStatus.LOADING ? (
                      <div className="w-full h-40 flexCenter">
                        <Spinner />
                      </div>
                    ) : (
                      <div>
                        {groupedBurnMoments.map((group, groupIndex) => (
                          <div key={groupIndex}>
                            <p className="pl-2 mt-4 text-lg font-bold">
                              {group.tier} Moments
                            </p>
                            <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
                              {group.moments.map(
                                (momentToBurn: any, i: number) => (
                                  <div
                                    key={i}
                                    className="flex relative w-full"
                                    onClick={() =>
                                      handleSelectionClick(momentToBurn)
                                    }
                                  >
                                    <SelectionCard
                                      image={momentToBurn.templateData.immutableData.thumbnail?.replaceAll(
                                        '%20',
                                        '%2520'
                                      )}
                                      name={
                                        momentToBurn.templateData.immutableData
                                          .headline
                                      }
                                      isSelected={selectedBurnMomentIds.includes(
                                        momentToBurn.id
                                      )}
                                      player={
                                        momentToBurn.templateData.immutableData
                                          .player
                                      }
                                      serial={
                                        momentToBurn.mintNumber || 'Reward'
                                      }
                                      playersJumperNumber={
                                        momentToBurn.templateData.immutableData
                                          ?.playersJumperNumber
                                      }
                                      tier={
                                        momentToBurn.templateData.immutableData
                                          .tier
                                      }
                                      maxSerial={
                                        momentToBurn.templateData.immutableData
                                          ?.maxSerial
                                      }
                                      circulatingSupply={
                                        momentToBurn.templateData.immutableData
                                          .circulatingSupply
                                      }
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <section id="faq" className="pt-14 w-full">
          <FaqSection header="FAQs" faqName="momentBurnPage" />
        </section>
      </div>
      <Grid container className="bg-black">
        <Grid item sx={{ width: '100%' }}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  )
}

export default RipperSkippersTradePage
