export const GET_USDC_BALANCE = `
  import FungibleToken from 0xFungibleToken
  import FiatToken from 0xFiatToken

  pub fun main(address: Address): UFix64? {
    let account = getAccount(address)
    if let vaultRef = account.getCapability(FiatToken.VaultBalancePubPath).borrow<&FiatToken.Vault{FungibleToken.Balance}>() {
      return vaultRef.balance
    }
    return nil
  }
`
