import { getAllDuplicateIds } from 'services/api.service'
import * as Sentry from '@sentry/browser'

export const testnetDuplicateIds: string[] = [
  // testnet ids
  '571',
  '1474',
  '1473',
  '1472',
  '165',
  '166',
  '1530',
  '1529',
  '1528',
  '94',
  '129',
  '130',
  '1606',
  '1605',
  '1604',
  '1078',
  '1080',
  '1081',
  '1609',
  '1610',
  '1611',
  '1652',
  '1616',
  '1618',
  '1515'
]
// mainnet ids
export const mainnetDuplicateIds: string[] = [
  '41575',
  '37798',
  '41727',
  '38498',
  '42226',
  '34485',
  '42156',
  '38329',
  '43799',
  '43283',
  '39574',
  '41863',
  '41280',
  '36367',
  '44460',
  '37443',
  '33815',
  '36325',
  '39314',
  '40417',
  '35467',
  '39800',
  '37556',
  '43305',
  '35157',
  '37946',
  '33795',
  '39864',
  '41767',
  '37694',
  '43218',
  '36428',
  '34630',
  '38448',
  '44380',
  '35772',
  '40730',
  '43205',
  '38644',
  '36369',
  '34481',
  '41147',
  '40390',
  '36696',
  '40708',
  '43150',
  '38979',
  '43909',
  '43157',
  '35509',
  '40287',
  '39238',
  '39925',
  '42475',
  '34790',
  '40192',
  '99445',
  '44017',
  '43798',
  '40989',
  '39569',
  '41190',
  '43509',
  '36062',
  '42429',
  '37333',
  '41377',
  '40915',
  '42615',
  '43564',
  '40236',
  '41515',
  '36818',
  '41194',
  '42380',
  '41576',
  '42165',
  '38339',
  '43209',
  '41887',
  '38069',
  '39976',
  '36392',
  '42228',
  '44461',
  '33976',
  '43102',
  '34627',
  '43167',
  '37250',
  '40309',
  '41017',
  '43381',
  '41562',
  '33533',
  '34834',
  '42851',
  '37897',
  '42664',
  '37278',
  '41619',
  '38447',
  '41274',
  '42371',
  '41766',
  '41048',
  '40429',
  '34003',
  '36284',
  '37924',
  '42262',
  '43158',
  '39819',
  '37642',
  '37868',
  '41762',
  '38338',
  '35175',
  '42168',
  '42376',
  '37160',
  '35653',
  '41369',
  '43115',
  '44381',
  '40959',
  '34489',
  '37483',
  '35126',
  '38383',
  '36673',
  '44231',
  '41045',
  '41726',
  '39163',
  '36819',
  '39828',
  '38871',
  '41187',
  '34086',
  '40950',
  '44012',
  '40058',
  '38915',
  '38966',
  '40235',
  '40668',
  '37702',
  '36390',
  '39193',
  '35850',
  '43907',
  '39082',
  '36567',
  '40447',
  '41193',
  '39615',
  '34039',
  '37439',
  '43635',
  '35158',
  '37002',
  '33944',
  '42159',
  '38894',
  '39330',
  '43047',
  '42321',
  '36362',
  '41836',
  '39923',
  '41294',
  '36616',
  '43164',
  '34190',
  '42181',
  '34792',
  '40194',
  '35507',
  '40285',
  '35934',
  '35465',
  '38386',
  '35264',
  '42227',
  '41885',
  '34567',
  '35906',
  '41108',
  '37053',
  '37700',
  '44384',
  '42473',
  '36869',
  '37121',
  '41317',
  '41277',
  '40204',
  '42619',
  '39458',
  '35846',
  '39047',
  '33816',
  '36326',
  '41514',
  '37899',
  '42666',
  '41074',
  '41996',
  '41864',
  '38328',
  '38452',
  '39723',
  '40161',
  '36697',
  '36522',
  '41525',
  '38067',
  '40445',
  '37950',
  '34980',
  '39557',
  '39832',
  '41834',
  '37299',
  '41699',
  '36532',
  '41034',
  '36815',
  '47046',
  '40307',
  '39607',
  '39237',
  '40680',
  '33536',
  '34837',
  '42854',
  '41474',
  '40415',
  '34904',
  '41517',
  '34237',
  '43089',
  '43849',
  '37925',
  '39317',
  '40372',
  '38897',
  '43279',
  '40842',
  '41749',
  '40490',
  '42833',
  '40911',
  '41109',
  '41733',
  '36001',
  '38270',
  '40678',
  '40113',
  '40716',
  '38970',
  '43530',
  '38720',
  '40454',
  '35839',
  '43506',
  '39164',
  '42764',
  '41189',
  '43379',
  '39313',
  '36360',
  '37054',
  '36004',
  '35546',
  '40532',
  '42194',
  '41273',
  '38896',
  '40917',
  '34090',
  '40954',
  '38456',
  '43560',
  '40162',
  '36664',
  '40206',
  '44416',
  '41125',
  '39048',
  '33798',
  '39867',
  '37641',
  '34088',
  '40952',
  '33113',
  '43784',
  '44376',
  '42368',
  '39935',
  '38154',
  '40976',
  '38071',
  '43905',
  '42160',
  '41728',
  '35043',
  '43555',
  '39554',
  '43888',
  '33503',
  '41855',
  '43885',
  '38982',
  '39598',
  '39614',
  '39632',
  '39642',
  '43848',
  '42548',
  '36429',
  '42374',
  '39797',
  '41702',
  '37247',
  '39192',
  '38245',
  '38384',
  '37787',
  '38874',
  '35657',
  '41373',
  '35816',
  '39937',
  '38705',
  '42545',
  '37882',
  '40667',
  '35841',
  '38781',
  '39818',
  '42263',
  '34628',
  '39674',
  '40710',
  '39162',
  '38695',
  '41281',
  '42195',
  '41765',
  '37186',
  '40848',
  '37441',
  '41375',
  '33532',
  '34833',
  '42850',
  '40413',
  '35847',
  '39235',
  '41986',
  '37596',
  '43049',
  '41513',
  '41134',
  '33501',
  '37922',
  '41759',
  '40912',
  '41700',
  '40486',
  '43971',
  '36525',
  '37474',
  '68871',
  '34484',
  '40455',
  '36042',
  '39576',
  '41751',
  '41561',
  '32828',
  '35848',
  '39328',
  '38916',
  '35506',
  '40284',
  '39936',
  '37112',
  '34458',
  '39112',
  '43557',
  '41316',
  '36672',
  '41739',
  '38969',
  '37639',
  '43153',
  '38696',
  '43226',
  '39974',
  '43559',
  '33796',
  '39865',
  '43701',
  '40060',
  '37554',
  '36208',
  '37298',
  '42322',
  '40456',
  '37991',
  '39618',
  '36125',
  '36565',
  '38121',
  '44385',
  '37161',
  '33534',
  '34835',
  '42852',
  '35173',
  '41105',
  '41076',
  '35214',
  '37693',
  '36427',
  '36999',
  '41191',
  '40963',
  '41196',
  '41574',
  '34241',
  '40715',
  '42010',
  '38495',
  '43050',
  '34249',
  '43282',
  '39568',
  '33817',
  '36327',
  '37593',
  '36431',
  '40960',
  '37786',
  '40165',
  '38450',
  '41736',
  '36524',
  '41997',
  '43782',
  '39553',
  '34193',
  '43378',
  '41783',
  '38873',
  '44417',
  '42688',
  '44065',
  '34626',
  '35642',
  '36492',
  '40371',
  '44388',
  '41976',
  '37869',
  '38782',
  '39919',
  '40719',
  '42979',
  '35655',
  '41371',
  '40210',
  '39609',
  '39978',
  '43380',
  '44232',
  '40306',
  '40991',
  '35791',
  '44462',
  '39046',
  '68874',
  '39798',
  '39606',
  '40126',
  '40990',
  '43765',
  '38249',
  '43155',
  '43217',
  '41734',
  '41516',
  '37947',
  '36866',
  '37118',
  '36393',
  '42430',
  '42549',
  '41975',
  '41195',
  '40709',
  '32829',
  '37619',
  '40237',
  '34486',
  '36670',
  '43636',
  '36867',
  '37119',
  '41578',
  '39045',
  '39730',
  '36564',
  '38246',
  '38453',
  '40978',
  '42373',
  '42264',
  '41126',
  '35217',
  '35466',
  '40443',
  '33911',
  '36044',
  '43972',
  '43281',
  '41738',
  '35372',
  '47047',
  '34487',
  '44377',
  '38918',
  '36865',
  '37117',
  '42164',
  '42382',
  '38351',
  '39817',
  '42689',
  '37485',
  '41737',
  '41974',
  '36205',
  '36000',
  '35046',
  '41122',
  '38122',
  '39619',
  '38698',
  '43207',
  '37994',
  '37923',
  '35547',
  '40533',
  '40446',
  '39605',
  '41837',
  '44018',
  '33913',
  '41790',
  '37446',
  '35644',
  '36494',
  '43763',
  '37052',
  '43551',
  '34087',
  '40951',
  '44419',
  '38341',
  '41600',
  '40209',
  '36618',
  '36046',
  '41747',
  '39079',
  '40666',
  '36695',
  '44230',
  '40238',
  '38455',
  '43796',
  '35597',
  '41761',
  '35654',
  '41370',
  '35469',
  '41854',
  '35155',
  '42508',
  '43717',
  '38749',
  '44415',
  '35838',
  '35625',
  '39743',
  '44049',
  '57957',
  '44368',
  '40789',
  '41740',
  '43118',
  '39926',
  '40571',
  '37056',
  '43141',
  '38701',
  '43507',
  '41146',
  '37249',
  '38745',
  '42323',
  '41560',
  '40414',
  '36663',
  '36521',
  '36430',
  '41565',
  '37159',
  '34978',
  '39966',
  '34482',
  '38382',
  '38419',
  '36817',
  '34793',
  '40195',
  '43761',
  '33914',
  '36682',
  '37472',
  '43303',
  '41748',
  '43887',
  '41884',
  '37948',
  '35932',
  '43908',
  '39633',
  '39643',
  '39648',
  '39654',
  '38497',
  '35045',
  '43797',
  '42547',
  '40664',
  '35774',
  '40732',
  '42433',
  '39965',
  '41528',
  '37799',
  '33955',
  '40572',
  '36061',
  '39722',
  '36681',
  '38420',
  '40308',
  '38103',
  '37949',
  '41378',
  '38646',
  '41882',
  '38748',
  '34040',
  '36694',
  '37738',
  '36667',
  '41724',
  '35545',
  '40531',
  '35656',
  '41372',
  '42006',
  '43208',
  '37279',
  '41956',
  '36475',
  '38703',
  '37696',
  '39608',
  '40386',
  '35508',
  '40286',
  '35218',
  '43702',
  '39044',
  '37597',
  '38832',
  '42509',
  '43553',
  '40305',
  '40488',
  '37001',
  '34488',
  '43302',
  '41018',
  '41197',
  '44398',
  '39209',
  '36563',
  '43850',
  '41959',
  '35933',
  '35174',
  '38704',
  '42191',
  '33535',
  '34836',
  '42853',
  '44463',
  '57971',
  '57954',
  '44378',
  '43463',
  '34041',
  '41793',
  '43117',
  '44046',
  '41577',
  '40057',
  '36002',
  '36364',
  '36665',
  '37879',
  '37115',
  '40918',
  '43785',
  '42650',
  '40112',
  '33499',
  '43764',
  '42193',
  '42085',
  '40416',
  '39080',
  '42834',
  '38833',
  '34000',
  '36281',
  '39636',
  '39646',
  '39651',
  '39657',
  '43088',
  '37557',
  '42648',
  '43558',
  '39831',
  '33979',
  '43105',
  '33945',
  '43550',
  '40961',
  '40649',
  '40585',
  '39921',
  '39672',
  '43762',
  '36476',
  '36533',
  '37867',
  '42830',
  '38967',
  '44014',
  '39566',
  '43781',
  '35156',
  '37296',
  '38327',
  '39968',
  '40163',
  '44141',
  '42617',
  '42649',
  '42229',
  '37797',
  '34629',
  '36666',
  '38342',
  '38499',
  '37114',
  '36652',
  '43552',
  '38917',
  '41073',
  '37878',
  '41835',
  '41701',
  '34981',
  '42324',
  '35842',
  '37553',
  '41883',
  '35371',
  '42968',
  '39728',
  '38325',
  '42381',
  '36420',
  '43970',
  '39924',
  '41033',
  '44140',
  '41107',
  '40202',
  '43394',
  '44045',
  '42432',
  '38746',
  '35646',
  '36496',
  '37704',
  '39829',
  '43107',
  '33956',
  '36477',
  '34460',
  '39114',
  '37900',
  '42667',
  '40845',
  '40207',
  '43110',
  '92229',
  '35627',
  '39745',
  '41960',
  '33958',
  '38721',
  '34192',
  '41035',
  '40791',
  '41787',
  '57956',
  '40388',
  '41279',
  '40707',
  '41047',
  '43108',
  '35213',
  '44387',
  '37113',
  '41957',
  '40056',
  '33912',
  '40111',
  '41106',
  '36531',
  '37734',
  '41563',
  '40426',
  '38780',
  '42510',
  '41292',
  '37785',
  '37881',
  '42802',
  '33959',
  '35373',
  '35773',
  '40731',
  '40428',
  '37795',
  '41020',
  '42167',
  '42765',
  '44367',
  '36391',
  '57972',
  '39616',
  '41293',
  '42472',
  '36127',
  '40977',
  '39920',
  '34982',
  '41703',
  '41994',
  '39597',
  '39613',
  '39631',
  '39641',
  '43140',
  '37055',
  '41016',
  '39315',
  '39575',
  '43166',
  '36650',
  '42378',
  '38643',
  '34570',
  '35909',
  '35263',
  '36060',
  '43563',
  '39269',
  '38070',
  '37789',
  '35154',
  '39673',
  '34576',
  '35963',
  '37473',
  '35645',
  '36495',
  '41133',
  '43510',
  '42182',
  '40389',
  '37486',
  '43280',
  '39573',
  '41283',
  '41148',
  '39078',
  '35849',
  '39617',
  '34791',
  '40193',
  '40665',
  '92232',
  '37622',
  '35628',
  '39746',
  '41037',
  '41124',
  '36649',
  '43206',
  '44386',
  '39329',
  '40444',
  '42367',
  '36566',
  '40677',
  '40790',
  '41769',
  '39922',
  '37871',
  '38248',
  '41024',
  '36653',
  '39556',
  '42651',
  '34903',
  '43051',
  '40489',
  '41881',
  '38779',
  '38872',
  '34089',
  '40953',
  '39933',
  '41319',
  '41568',
  '39595',
  '39611',
  '39629',
  '39639',
  '38350',
  '38590',
  '37447',
  '39918',
  '38326',
  '43851',
  '42261',
  '33975',
  '43101',
  '43109',
  '42978',
  '40208',
  '37649',
  '43149',
  '35209',
  '40211',
  '45298',
  '37438',
  '35624',
  '39742',
  '41188',
  '38102',
  '37621',
  '41318',
  '43529',
  '37162',
  '41977',
  '39634',
  '39644',
  '39649',
  '39655',
  '38968',
  '38068',
  '40487',
  '43142',
  '42506',
  '35216',
  '38697',
  '38778',
  '37695',
  '40568',
  '40387',
  '41075',
  '43906',
  '42831',
  '37595',
  '43677',
  '40844',
  '39932',
  '41564',
  '33813',
  '36323',
  '44366',
  '40992',
  '42507',
  '41295',
  '35626',
  '39744',
  '38588',
  '37277',
  '44459',
  '43573',
  '43227',
  '33910',
  '37555',
  '34247',
  '38272',
  '35468',
  '43114',
  '34483',
  '43556',
  '42180',
  '35817',
  '39938',
  '33957',
  '44015',
  '41072',
  '38271',
  '37336',
  '42369',
  '42687',
  '39457',
  '37470',
  '35176',
  '36671',
  '34191',
  '35931',
  '40793',
  '38104',
  '43561',
  '42801',
  '43111',
  '39327',
  '41838',
  '39820',
  '44379',
  '39165',
  '38354',
  '45295',
  '39461',
  '42375',
  '37703',
  '37697',
  '37880',
  '42471',
  '38273',
  '33111',
  '40650',
  '43139',
  '33947',
  '38587',
  '36669',
  '39969',
  '39596',
  '39612',
  '39630',
  '39640',
  '40569',
  '42178',
  '43562',
  '37442',
  '35818',
  '39939',
  '39567',
  '37444',
  '41785',
  '37281',
  '41725',
  '38449',
  '41792',
  '39934',
  '34239',
  '38123',
  '38645',
  '43156',
  '38699',
  '43886',
  '37594',
  '33977',
  '43103',
  '37620',
  '43571',
  '41791',
  '44233',
  '36421',
  '41315',
  '33946',
  '42977',
  '40679',
  '36816',
  '37297',
  '44464',
  '43395',
  '41784',
  '34461',
  '39115',
  '41282',
  '37187',
  '40849',
  '38702',
  '43889',
  '57969',
  '43968',
  '44375',
  '40974',
  '41732',
  '35548',
  '40534',
  '33794',
  '39863',
  '43048',
  '41144',
  '39725',
  '34248',
  '38875',
  '36534',
  '42795',
  '57968',
  '42007',
  '37788',
  '35211',
  '39637',
  '39647',
  '39652',
  '39658',
  '41760',
  '37248',
  '39729',
  '41567',
  '36620',
  '42260',
  '40711',
  '42975',
  '36394',
  '38125',
  '38496',
  '37896',
  '42663',
  '42377',
  '33110',
  '39676',
  '44374',
  '43531',
  '35265',
  '41526',
  '38352',
  '44361',
  '37737',
  '43639',
  '37334',
  '35935',
  '43165',
  '34043',
  '39977',
  '35771',
  '40729',
  '42762',
  '39724',
  '43116',
  '41019',
  '34189',
  '36478',
  '36998',
  '43783',
  '41137',
  '43572',
  '37185',
  '40847',
  '42616',
  '57955',
  '38124',
  '34462',
  '39116',
  '41618',
  '34568',
  '35907',
  '41136',
  '42009',
  '40453',
  '44360',
  '40584',
  '36530',
  '35770',
  '40728',
  '37280',
  '36003',
  '44048',
  '68870',
  '40570',
  '36043',
  '39830',
  '44382',
  '34238',
  '37992',
  '38155',
  '36361',
  '33109',
  '38893',
  '42618',
  '42431',
  '39572',
  '35047',
  '42370',
  '41958',
  '43800',
  '37735',
  '44016',
  '41857',
  '35127',
  '39166',
  '43508',
  '33500',
  '36479',
  '92230',
  '38591',
  '40457',
  '40059',
  '36207',
  '43549',
  '34979',
  '39459',
  '35212',
  '38416',
  '39236',
  '38105',
  '39732',
  '41789',
  '33943',
  '42691',
  '37246',
  '38642',
  '43678',
  '40718',
  '40993',
  '33797',
  '39866',
  '37300',
  '37487',
  '40916',
  '43852',
  '34566',
  '35905',
  '43168',
  '43969',
  '37189',
  '40851',
  '42157',
  '39210',
  '37471',
  '34459',
  '39113',
  '39316',
  '40234',
  '43306',
  '40110',
  '44066',
  '35643',
  '36493',
  '44418',
  '37796',
  '41376',
  '42832',
  '41025',
  '45297',
  '36363',
  '41750',
  '37000',
  '35267',
  '37870',
  '41275',
  '41527',
  '33978',
  '43104',
  '40427',
  '40676',
  '34002',
  '36283',
  '37445',
  '43143',
  '57970',
  '41888',
  '40962',
  '37643',
  '41858',
  '43637',
  '41145',
  '37116',
  '34794',
  '40196',
  '41601',
  '39239',
  '37736',
  '42763',
  '42761',
  '37926',
  '39975',
  '43377',
  '40975',
  '39635',
  '39645',
  '39650',
  '39656',
  '37484',
  '42474',
  '42166',
  '42379',
  '41291',
  '44229',
  '34245',
  '38980',
  '42495',
  '35369',
  '42008',
  '44013',
  '44399',
  '38269',
  '39917',
  '43152',
  '39799',
  '36209',
  '41987',
  '41993',
  '43464',
  '33502',
  '68873',
  '41049',
  '37898',
  '42665',
  '41276',
  '39460',
  '34577',
  '35964',
  '39270',
  '38247',
  '40164',
  '35544',
  '40530',
  '40914',
  '37995',
  '45296',
  '39555',
  '41475',
  '44364',
  '36693',
  '38340',
  '36064',
  '42794',
  '38353',
  '34246',
  '42084',
  '36124',
  '36617',
  '37188',
  '40850',
  '35172',
  '39081',
  '39326',
  '39594',
  '39610',
  '39628',
  '39638',
  '40792',
  '34042',
  '68872',
  '35819',
  '39940',
  '38983',
  '57953',
  '39816',
  '41036',
  '35505',
  '40283',
  '36206',
  '41379',
  '40717',
  '39570',
  '36370',
  '36063',
  '41995',
  '42320',
  '38747',
  '36126',
  '33112',
  '37332',
  '34001',
  '36282',
  '43527',
  '40843',
  '34240',
  '39731',
  '36045',
  '43718',
  '41135',
  '44365',
  '36619',
  '34569',
  '35908',
  '41730',
  '39967',
  '35820',
  '39941',
  '42690',
  '42969',
  '37335',
  '41786',
  '44383',
  '41559',
  '45294',
  '35044',
  '34490',
  '40125',
  '43638',
  '39796',
  '34574',
  '35961',
  '43154',
  '36651',
  '41763',
  '38418',
  '43574',
  '43528',
  '40841',
  '38446',
  '42976',
  '35840',
  '34575',
  '35962',
  '35598',
  '39675',
  '42225',
  '36868',
  '37120',
  '37163',
  '38101',
  '41123',
  '34004',
  '36285',
  '35210',
  '36123',
  '35215',
  '44047',
  '40425',
  '37701',
  '38895',
  '37440',
  '36366',
  '38385',
  '42192',
  '40203',
  '39726',
  '43575',
  '40114',
  '41529',
  '38981',
  '35266',
  '41768',
  '41731',
  '41973',
  '37623',
  '36523',
  '42652',
  '35792',
  '37993',
  '34573',
  '35960',
  '33814',
  '36324',
  '42494',
  '38454',
  '42546',
  '42179',
  '41046',
  '38589',
  '37640',
  '43151',
  '40205',
  '35370',
  '43304',
  '41566',
  '38417',
  '38919',
  '36368',
  '70488',
  '65633',
  '65634',
  '68206',
  '70536',
  '70490',
  '70532',
  '65636',
  '70535',
  '70534',
  '70470',
  '68209',
  '68204',
  '70487',
  '65632',
  '70489',
  '68207',
  '68203',
  '70533',
  '65638',
  '70469',
  '70471',
  '68208',
  '70473',
  '65637',
  '68205',
  '65639',
  '65635',
  '70491',
  '70472',
  '68210',
  '59079',
  '59341',
  '59115',
  '70355',
  '59309',
  '59406',
  '59306',
  '59403',
  '59113',
  '59078',
  '59340',
  '58813',
  '58892',
  '58811',
  '58890',
  '70358',
  '59077',
  '59339',
  '70359',
  '58810',
  '58889',
  '70360',
  '58815',
  '58894',
  '59305',
  '59402',
  '70362',
  '59117',
  '59080',
  '59342',
  '59083',
  '59345',
  '58814',
  '58893',
  '59081',
  '59343',
  '59112',
  '58817',
  '58896',
  '59307',
  '59404',
  '70361',
  '59308',
  '59405',
  '59304',
  '59401',
  '70356',
  '59303',
  '59400',
  '59116',
  '59119',
  '58812',
  '58891',
  '70357',
  '59082',
  '59344',
  '59076',
  '59338',
  '59118',
  '59302',
  '59399',
  '59114',
  '58816',
  '58895',
  '84203',
  '91433',
  '84204',
  '70819',
  '91434',
  '90032',
  '70818',
  '90031',
  '70661',
  '70662',
  '90046',
  '90040',
  '90042',
  '90043',
  '90044',
  '90047',
  '90041',
  '90045',
  '85584',
  '85755',
  '82404',
  '82403',
  '83073',
  '83074',
  '85583',
  '85754',
  '83076',
  '85585',
  '85756',
  '82405',
  '83075',
  '85586',
  '85757',
  '82402',
  '98395',
  '88151',
  '98394',
  '88152',
  '88088',
  '88087',
  '88150',
  '98396',
  '88149',
  '88089',
  '98397',
  '88090',
  '90166',
  '90186',
  '90128',
  '90132',
  '90137',
  '90121',
  '90129',
  '90133',
  '90190',
  '90234',
  '90185',
  '90163',
  '90127',
  '90131',
  '90122',
  '90136',
  '90183',
  '90189',
  '90233',
  '90188',
  '90232',
  '90184',
  '90120',
  '90138',
  '90130',
  '90134',
  '90187',
  '90231',
  '90164',
  '90123',
  '90165',
  '90135',
  '95482',
  '95481',
  '99161',
  '99303',
  '99445',
  '99541',
  '99727',
  '99699',
  '99981',
  '100226',
  '100446',
  '100445',
  '103164',
  '103165',
  '103166',
  '103167',
  '103281',
  '103282',
  '101958',
  '100217' // manually added to allow user to exchange for a 'perfect' 400/400 serial number of same player
]

export let duplicateIds: string[] = [...mainnetDuplicateIds]

export async function getDuplicateIds() {
  try {
    const dupeIds: string[] = await getAllDuplicateIds()
    if (dupeIds?.length > 0) duplicateIds.push(...dupeIds)
    return duplicateIds
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return duplicateIds
  }
}
getDuplicateIds()
