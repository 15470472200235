export const CREATE_COLLECTION = `
  import AFLNFT from 0xAFLContracts
  import NonFungibleToken from 0xNFT
  import FungibleToken from 0xFungibleToken
  import MetadataViews from 0xMetadataViews
  import FiatToken from 0xFiatToken
  import USDCFlow from 0xUSDCFlow

 transaction {
    prepare(signer: AuthAccount) {

      if signer.borrow<&AFLNFT.Collection>(from: AFLNFT.CollectionStoragePath) == nil {
        let collection <- AFLNFT.createEmptyCollection()
        signer.save<@NonFungibleToken.Collection>(<-collection, to: AFLNFT.CollectionStoragePath)
        signer.link<&{AFLNFT.AFLNFTCollectionPublic}>(AFLNFT.CollectionPublicPath, target: AFLNFT.CollectionStoragePath)
      }

       if signer.borrow<&FiatToken.Vault>(from: FiatToken.VaultStoragePath) == nil {
          signer.save(
              <-FiatToken.createEmptyVault(),
              to: FiatToken.VaultStoragePath
          )
          signer.link<&FiatToken.Vault{FungibleToken.Receiver}>(
              FiatToken.VaultReceiverPubPath,
              target: FiatToken.VaultStoragePath
          )
          signer.link<&FiatToken.Vault{FiatToken.ResourceId}>(
              FiatToken.VaultUUIDPubPath,
              target: FiatToken.VaultStoragePath
          )
          signer.link<&FiatToken.Vault{FungibleToken.Balance}>(
              FiatToken.VaultBalancePubPath,
              target: FiatToken.VaultStoragePath
          )
      }

      if signer.borrow<&USDCFlow.Vault>(from: USDCFlow.VaultStoragePath) == nil {
          signer.save(
              <-USDCFlow.createEmptyVault(),
              to: USDCFlow.VaultStoragePath
          )
          signer.link<&USDCFlow.Vault{FungibleToken.Receiver}>(
              USDCFlow.ReceiverPublicPath,
              target: USDCFlow.VaultStoragePath
          )
          signer.link<&USDCFlow.Vault{FungibleToken.Balance, MetadataViews.Resolver}>(
              USDCFlow.VaultPublicPath,
              target: USDCFlow.VaultStoragePath
          )
      }
    }
  }
`
