import React from 'react'
import { BadgeMeta } from 'types'

interface BadgesProps {
  badges: BadgeMeta[]
  showTooltip?: boolean
}

export const Badges: React.FC<BadgesProps> = ({
  badges,
  showTooltip = true
}) => {
  if (!badges) return null
  return (
    <>
      {badges.map((badge, index) => (
        <div key={badge.id || index} className="flex">
          <img
            src={badge.imageUrl}
            alt={badge.name}
            className="h-6"
            loading="lazy"
            onMouseOver={() => {
              if (!showTooltip) return
              const tooltip = document.createElement('div')
              tooltip.innerText = badge.name
              tooltip.style.position = 'absolute'
              tooltip.style.translate = '-50% -50%'
              tooltip.style.backgroundColor = '#5e5e5e'
              tooltip.style.color = '#ffffff'
              tooltip.style.fontWeight = '700'
              tooltip.style.textAlign = 'center'
              tooltip.style.padding = '6px 8px'
              tooltip.style.borderRadius = '3px'
              tooltip.style.maxWidth = '200px'
              tooltip.style.zIndex = '999'
              tooltip.setAttribute('id', 'tooltip')
              document.body.appendChild(tooltip)
            }}
            onMouseMove={(event) => {
              if (!showTooltip) return
              const tooltip = document.getElementById('tooltip')
              if (tooltip) {
                tooltip.style.left = event.pageX + 'px'
                tooltip.style.top = event.pageY - 45 + 'px'
              }
            }}
            onMouseOut={() => {
              if (!showTooltip) return
              const tooltip = document.getElementById('tooltip')
              if (tooltip) {
                tooltip.remove()
              }
            }}
          />
        </div>
      ))}
    </>
  )
}
