import { Grid } from '@mui/material'
import CustomButton from 'components/Button/CustomButton'
import { Link } from 'react-router-dom'
import bookmarkOn from '/assets/Icon/Bookmark_On.svg'
import bookmarkOff from '/assets/Icon/Bookmark_Off.svg'
import { PackBack3 } from 'components/Moment/PackBack'
import { toggleWatchlistId, getWatchlistIds } from 'utils/watchlist'
import { Dispatch, useState } from 'react'
import { SetStateAction } from 'react'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import skeleton from '/assets/Image/OpenPack/Skeleton3.png'
import skeletonPack from '/assets/Image/thumbnails/skeleton.png'
import skeletonBall from '/assets/Image/thumbnails/skeleton-box.jpg'
import { Badges } from 'components/Card/Badges'
import useExchangeRate from 'hooks/useExchangeRate'
import { useSnackbar } from 'hooks/useSnackbar'
import { jerseyBadge } from 'utils/jerseyBadge'
import { collectionIds } from 'utils/collectionIds'
interface ItemCardProps {
  link: string
  image: string
  name: string
  player?: string
  subtitle?: string
  serial?: string
  isGenesisBall?: boolean
  date: string
  badges?: any
  type?: 'nft' | 'pack' | 'reward' | 'watchlist' | 'club-badge'
  tier?: string
  teamOfInvolvedPlayer?: string
  filterOpen?: boolean
  id?: number
  templateId?: string
  forSale?: boolean
  isDuplicate?: boolean
  refreshWatchlist?: Dispatch<SetStateAction<number>>
  salePrice?: string
  maxSerial?: string
  showWatchlistBtn?: boolean
  isNew?: boolean
  playersJumperNumber?: string
  freshestListingDate?: string
  issuedSupply?: string
  burntSupply?: string
  circulatingSupply?: string
  isSignedMoment?: string
}

export default function ItemCard(props: ItemCardProps) {
  const {
    link,
    image,
    name,
    player,
    serial,
    date,
    subtitle,
    type,
    tier,
    teamOfInvolvedPlayer,
    badges,
    isGenesisBall,
    filterOpen,
    templateId,
    forSale,
    isDuplicate,
    refreshWatchlist,
    salePrice,
    maxSerial,
    showWatchlistBtn,
    isNew,
    playersJumperNumber,
    freshestListingDate,
    issuedSupply,
    circulatingSupply,
    burntSupply,
    isSignedMoment
  } = props

  const [watchlist, setWatchlist] = useState<number[]>([])
  const [isLoaded, setIsLoaded] = useState(false)
  const { formatAUDPrice } = useExchangeRate()
  const { openSnackbar } = useSnackbar()

  const featureFlags = useFeatureFlags()

  const isTeamBadge = collectionIds['Team Badges 2023'].includes(
    parseInt(templateId || '0')
  )
  const showCirculatingInfo = !isGenesisBall && !isTeamBadge

  const toggleWatchlist = async () => {
    if (templateId && refreshWatchlist) {
      if (watchlist.includes(parseInt(templateId))) {
        openSnackbar('Removed from Watchlist')
      } else {
        openSnackbar('Added to Watchlist')
      }
      await toggleWatchlistId(parseInt(templateId))
      refreshWatchlist(Math.random())
      setWatchlist(getWatchlistIds())
    }
  }

  useState(() => {
    setWatchlist(getWatchlistIds())
  })

  const existsInWatchlist = watchlist.includes(parseInt(templateId || '0'))

  const isSerialJerseyNumber = playersJumperNumber === serial

  return (
    <Grid
      xs={6}
      sm={!filterOpen ? 4 : 6}
      md={!filterOpen ? 3 : 4}
      lg={!filterOpen ? 2 : 3}
      className="justify-center items-center !p-[6px] xl:!p-3"
      item
    >
      {type === 'pack' && (
        <div>
          <Link to={link} className="w-full">
            <PackBack3>
              <div className="aspect-square relative">
                <img
                  src={image}
                  alt={`${name} NFT`}
                  onLoad={() => setIsLoaded(true)}
                />
                <img
                  src={skeletonPack}
                  alt=""
                  className={`skippers-index-owned absolute top-0 !duration-[1500ms] ${
                    !isLoaded ? 'opacity-1 blur-none' : 'opacity-0 blur-lg'
                  }`}
                />
              </div>
              <p className="mb-3 font-podium49 text-lg tracking-wider !leading-none text-center uppercase">
                {name}
              </p>
              {/* <span className="text-[10px] font-bold text-grey2">{series}</span> */}
              <div className="flex justify-center items-center">
                <CustomButton
                  type="button"
                  model="primary"
                  variant="contained"
                  label="Open pack"
                />
              </div>
            </PackBack3>
          </Link>
        </div>
      )}
      {type === 'nft' && (
        <div
          className={`h-[100%] relative card-${
            tier?.toLowerCase() ?? 'common'
          } ${isGenesisBall && 'card-genesis'}`}
        >
          {forSale && featureFlags.MARKETPLACE_ACTIVE === true && (
            <div className="absolute top-0 left-6 z-50 p-2 bg-[#149E43] rounded-b-[4px]">
              <p className="text-[10px] font-bold">For Sale</p>
            </div>
          )}
          {isDuplicate && (
            <div className="absolute top-0 left-6 z-50 p-2 bg-[#9747FF] rounded-b-[4px]">
              <p className="text-[10px] font-bold">Duplicate</p>
            </div>
          )}
          {isNew && (
            <div className="absolute top-0 left-6 z-50 p-2 bg-[#149E43] rounded-b-[4px]">
              <p className="text-[10px] font-bold">New</p>
            </div>
          )}
          <div
            className={`card-back flex flex-col justify-between h-[100%] xl:p-5 overflow-hidden`}
          >
            <Link to={link} className="flexCol justify-between h-full">
            <div>
                <div
                  className={`aspect-square relative ${
                    isSignedMoment && 'signed-glow'
                  }`}
                >
                  <img
                    src={image}
                    alt={`${name} NFT`}
                    onLoad={() => setIsLoaded(true)}
                    className={`${!isGenesisBall && 'rounded-xl'} skippers-index-owned`}
                  />
                  <img
                    src={isGenesisBall ? skeletonBall : skeleton}
                    alt=""
                    className={`skippers-index-owned absolute top-0 !duration-[1500ms] ${
                      !isLoaded ? 'opacity-1 blur-none' : 'opacity-0 blur-lg'
                    }`}
                  />
                </div>
                <p className="mt-2 mb-1 font-podium49 text-sm tracking-[0.3px] !leading-[15px] uppercase xl:text-lg xl:tracking-wide xl:!leading-[20px] truncate-2">
                  {name}
                </p>
                {isGenesisBall && (
                  <p className="text-xs font-normal lg:text-sm">
                    #{serial}/2022
                  </p>
                )}
                <div className="flex flex-col gap-1 mb-4 text-sm">
                  <p className="text-xs font-bold !leading-none text-grey3 xl:text-sm">
                    {player}
                  </p>
                  {isTeamBadge && (
                    <p className="text-xs font-bold !leading-none text-grey3 xl:text-sm">
                      {teamOfInvolvedPlayer}
                    </p>
                  )}
                  <div className="flex flex-wrap justify-between items-center">
                    <div className="flex items-center">
                      {tier && (
                        <p
                          className={`font-bold !leading-none text-xs lg:text-sm pr-[6px] ${
                            tier === 'Common' && 'text-grey3'
                          } ${tier === 'Deluxe' && 'text-deluxe'} ${
                            tier === 'Ovation' && 'text-ovation'
                          } ${tier === 'Elite' && 'text-elite'}`}
                        >
                          {tier}
                        </p>
                      )}
                      {!(isGenesisBall || isTeamBadge) && (
                        <p className="text-xs font-normal lg:text-sm">
                          #{serial} {maxSerial && ` / ${maxSerial}`}
                        </p>
                      )}
                    </div>
                    <div className="flex">
                      <>
                        {isSerialJerseyNumber && (
                          <Badges badges={[jerseyBadge]} />
                        )}
                        <Badges badges={badges} />
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <div
              className={`flex w-full items-center ${
                salePrice && featureFlags.MARKETPLACE_ACTIVE === true
                  ? 'justify-between'
                  : 'justify-end'
              }`}
            >
              {salePrice && featureFlags.MARKETPLACE_ACTIVE === true && (
                <div className="flex flex-col justify-start items-start">
                  <p className="text-xs font-bold text-grey2">Listed for</p>
                  <p className="text-sm font-bold">
                    A${formatAUDPrice(salePrice)}
                  </p>
                </div>
              )}
              {showWatchlistBtn && (
                <div className="flex gap-x-2 justify-center items-center p-2 h-9 bg-black2 rounded-md">
                  {/* <p className="font-bold text-sm leading-4">1</p> */}
                  <img
                    src={existsInWatchlist === true ? bookmarkOn : bookmarkOff}
                    alt=""
                    className="cursor-pointer"
                    onClick={() => toggleWatchlist()}
                  />
                </div>
              )}
            </div>
            {showCirculatingInfo && (
              <div className="@container border-t-[1px]  border-grey0 mt-3">
                <div className="flex flex-row @[160px]:flex-row justify-between w-full text-[10px] sm:text-xs mt-2">
                  <div className="flex gap-1 items-center">
                    <span className="@[210px]:hidden">📦</span>
                    <span className="hidden @[210px]:inline">Minted:</span>
                    <span>{issuedSupply}</span>
                    <span className="hidden @[250px]:inline">📦</span>
                  </div>
                  <div className="flex gap-1 items-center">
                    <span className="@[210px]:hidden">🔥</span>
                    <span className="hidden @[210px]:inline">Burnt:</span>
                    <span>{burntSupply}</span>
                    <span className="hidden @[250px]:inline">🔥</span>
                  </div>
                  <div className="flex gap-1 items-center">
                    <span className="@[210px]:hidden">🔄</span>
                    <span className="hidden @[210px]:inline">Circulating:</span>
                    <span>{circulatingSupply}</span>
                    <span className="hidden @[250px]:inline">🔄</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {type === 'watchlist' && (
        <div
          className={`h-[100%] card-${tier?.toLowerCase()} ${
            isGenesisBall && 'card-genesis'
          }`}
        >
          <div className={`card-back flex flex-col justify-between h-[100%]`}>
            <Link to={isGenesisBall ? `/generic-ball/${templateId}` : link}>
              <div className="aspect-square relative">
                <img
                  src={image}
                  alt={`${name} NFT`}
                  onLoad={() => setIsLoaded(true)}
                />
                <img
                  src={skeleton}
                  alt=""
                  className={`skippers-index-owned absolute top-0 !duration-[1500ms] ${
                    !isLoaded ? 'opacity-1 blur-none' : 'opacity-0 blur-lg'
                  }`}
                />
              </div>
              <p className="mt-2 mb-1 font-podium49 text-sm tracking-widest leading-[15px] uppercase lg:text-lg lg:tracking-wider lg:leading-[20px]">
                {name}
              </p>
              {isGenesisBall && (
                <p className="text-xs font-normal lg:text-sm">#{serial}/2022</p>
              )}
              <div className="flex flex-col gap-1 mb-4 text-sm">
                <p className="text-xs font-bold text-grey3 lg:pb-1 lg:text-sm">
                  {player}
                </p>
                <div className="flex flex-wrap justify-between items-center">
                  <div className="flex items-center">
                    {tier && (
                      <p
                        className={`font-bold text-xs text-grey3 lg:text-sm pr-[6px] ${
                          tier === 'Deluxe' && 'text-deluxe'
                        } ${tier === 'Ovation' && 'text-ovation'} ${
                          tier === 'Elite' && 'text-elite'
                        } `}
                      >
                        {tier}
                      </p>
                    )}
                    {!isGenesisBall && (
                      <p className="text-xs font-normal lg:text-sm">
                        #{maxSerial && ` / ${maxSerial}`}
                      </p>
                    )}
                  </div>
                  <div className="flex">
                    <Badges badges={badges} />
                  </div>
                </div>
              </div>
            </Link>
            <div className="flex justify-end w-full">
              <div
                className="flex gap-x-2 justify-center items-center p-2 h-9 bg-black2 rounded-md cursor-pointer"
                onClick={() => toggleWatchlist()}
              >
                {/* <p className="font-bold text-sm leading-4">1</p> */}
                <img src={bookmarkOn} alt="Bookmark image" />
              </div>
            </div>
          </div>
        </div>
      )}
      {type === 'reward' && (
        <div className={`h-[100%] card-genesis genesis-glow`}>
          <div className={`card-back h-[100%]`}>
            <Link to={link}>
              <img src={image} alt={`${name} NFT`} />
              <p className="my-2 font-podium49 text-xl tracking-wide leading-6 uppercase">
                {name}
              </p>
              <div className="flex gap-1 mb-4 text-sm">
                <p>{date}</p>
                {`\u2022`}
                <p>#{serial}</p>
              </div>
            </Link>
          </div>
        </div>
      )}
    </Grid>
  )
}
