export const SWAP_DUPES = `
import AFLNFT from 0xAFLContracts
import AFLBurnExchange from 0xAFLContracts

transaction(withdrawIDs: [UInt64]) {
  let usersCollectionRef: &AFLNFT.Collection

  prepare(signer: AuthAccount) {
      self.usersCollectionRef = signer
          .borrow<&AFLNFT.Collection>(from: AFLNFT.CollectionStoragePath)
          ?? panic("Account does not store an AFLNFT.Collection at the specified path")
  }

  execute {
      for id in withdrawIDs {
          if (AFLBurnExchange.getTokenId(id: id) != 0) {
              let dupeNft <- self.usersCollectionRef.withdraw(withdrawID: id)
              let newNFT <- AFLBurnExchange.swap(token: <- dupeNft)
              self.usersCollectionRef.deposit(token: <-newNFT)
          }
      }
  }
}`
