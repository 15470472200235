import { GET_ALL_NFTS_BY_ACCOUNT } from './scripts/getAllNFTsByAccount'
import * as fcl from '@blocto/fcl'
import * as Sentry from '@sentry/browser'
import {
  GET_LEGACY_LISTINGS_DETAILS_BY_ACCOUNT,
  GET_LISTINGS_DETAILS_BY_ACCOUNT
} from './scripts/getListingsDetailsByAccount'
import { GET_USDC_BALANCE } from './scripts/getFlowUSDCBalance.script'
import { CHECK_COLLECTION } from './scripts/checkCollection.script'
import { CREATE_COLLECTION } from './transactions/createCollection.tx'
import { waitForSealed } from 'utils/flow'
import { GET_TOTAL_BURNS_FOR_TEMPLATE } from './scripts/getTotalBurnsForTemplate.script'
import { GET_WUSDC_BALANCE } from './scripts/getFlowWUSDCBalance.script'
import { CHECK_WUSDC } from './scripts/checkWUSDC.script'

export const getBurntSupply = async (templateId: number): Promise<number> => {
  try {
    const res = await fcl.query({
      cadence: GET_TOTAL_BURNS_FOR_TEMPLATE,
      args: (arg: any, t: any) => [arg(templateId, t.UInt64)]
    })

    return res
  } catch (e) {
    Sentry.captureException(e)
    console.error(e)
    throw e
  }
}

export const getTotalListedMoments = async (
  address: string
): Promise<number> => {
  if (!address || !address.startsWith('0x')) return 0
  try {
    const collectionResults = await fcl.query({
      cadence: GET_LISTINGS_DETAILS_BY_ACCOUNT,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })

    const totalMoments = Object.keys(collectionResults).length
    return totalMoments
  } catch (e) {
    if (
      (e as { stack: string })?.stack?.includes(
        'could not borrow receiver reference'
      )
    ) {
      // soft error
      console.error('address not found or collection not setup')
      return 0
    } else {
      Sentry.captureException(e)
      console.error(e)
      throw e
    }
  }
}

export const getLegacyListedMoments = async (
  address: string
): Promise<number> => {
  if (!address || !address.startsWith('0x')) return 0
  try {
    const collectionResults = await fcl.query({
      cadence: GET_LEGACY_LISTINGS_DETAILS_BY_ACCOUNT,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })

    const totalMoments = Object.keys(collectionResults).length
    return totalMoments
  } catch (e) {
    if (
      (e as { stack: string })?.stack?.includes(
        'could not borrow receiver reference'
      )
    ) {
      // soft error
      console.error('address not found or collection not setup')
      return 0
    } else {
      Sentry.captureException(e)
      console.error(e)
      throw e
    }
  }
}

export const getTotalNonListedMoments = async (
  address: string
): Promise<number> => {
  if (!address || !address.startsWith('0x')) return 0
  try {
    const collectionResults = await fcl.query({
      cadence: GET_ALL_NFTS_BY_ACCOUNT,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })

    const totalMoments = collectionResults.length
    return totalMoments
  } catch (e) {
    if (
      (e as { stack: string }).stack.includes(
        'could not borrow receiver reference'
      )
    ) {
      // soft error
      console.error('address not found or collection not setup')
      return 0
    } else {
      Sentry.captureException(e)
      console.error(e)
      throw e
    }
  }
}

export const getTotalMoments = async (address: string): Promise<number> => {
  if (!address || !address.startsWith('0x')) return 0
  try {
    const collectionResults = await fcl.query({
      cadence: GET_ALL_NFTS_BY_ACCOUNT,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })

    const listedCollectionResults = await fcl.query({
      cadence: GET_LISTINGS_DETAILS_BY_ACCOUNT,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })

    const totalMoments =
      collectionResults.length + Object.keys(listedCollectionResults).length

    return totalMoments
  } catch (e) {
    if (
      (e as { stack: string }).stack.includes(
        'could not borrow receiver reference'
      )
    ) {
      // soft error
      console.error('address not found or collection not setup')
      return 0
    } else {
      Sentry.captureException(e)
      console.error(e)
      throw e
    }
  }
}

export const getUSDCBalanceForAccount = async (address: string) => {
  if (!address || !address.startsWith('0x')) return 0
  try {
    const res = await fcl.query({
      cadence: GET_USDC_BALANCE,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })
    const balance = parseFloat(res)
    if (isNaN(balance)) return 0
    return balance
  } catch (err) {
    Sentry.captureException(err)
    console.error(err)
    throw err
  }
}

// checks active wallet has WUSDC receiver capability setup
export const checkWUSDCAccountSetup = async (
  address: string
): Promise<boolean> => {
  try {
    const res = await fcl.query({
      cadence: CHECK_WUSDC,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })
    return res
  } catch (err) {
    Sentry.captureException(err)
    console.error(err)
    return false
  }
}

export const getWUSDCBalanceForAccount = async (address: string) => {
  if (!address || !address.startsWith('0x')) return 0
  if (!(await checkWUSDCAccountSetup(address))) return 0
  try {
    const res = await fcl.query({
      cadence: GET_WUSDC_BALANCE,
      args: (arg: any, t: any) => [arg(address, t.Address)]
    })
    const balance = parseFloat(res)
    if (isNaN(balance)) return 0
    return balance
  } catch (err) {
    Sentry.captureException(err)
    console.error(err)
    throw err
  }
}

export const collectionUtils = {
  check: async (addr: string) => {
    if (!addr || !addr.startsWith('0x')) return
    try {
      const res = await fcl.query({
        cadence: CHECK_COLLECTION,
        args: (arg: any, t: any) => [arg(addr, t.Address)]
      })
      return res
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
  },
  create: async (authFunc?: any) => {
    try {
      const res = await fcl.send([
        fcl.transaction(CREATE_COLLECTION),
        fcl.payer(authFunc),
        fcl.proposer(authFunc),
        fcl.authorizations([authFunc]),
        fcl.limit(9999)
      ])
      await waitForSealed(res.transactionId)
      return res
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
  }
}
