// Banxa API
import * as Sentry from '@sentry/browser'

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL
export interface BanxaOrderParams {
  walletAddress: string
  sourceCurrency: string
  targetCurrency: string
  returnUrlOnSuccess: string
}
export interface BanxaCreateNFTOrderParams extends BanxaOrderParams {
  tokenId: number
}

interface BanxaTransactionResponse {
  banxaTransaction: {
    order_type: string
    source_currency: string
    target_currency: string
    status: string
    user_id: string
    combination_id: number
    wallet_address: string
    banxa_order_id: string
    transaction_id: string | null
    blockchain: {
      id: number
      code: string
      description: string
    }
    source_amount: number | null
    target_amount: number | null
    updated_at: string | null
    id: number
    created_at: string
  }
  checkoutLink: string
  iFrameCheckout: string
}

const banxaCreateOrder = async (
  path: string,
  params: BanxaOrderParams
): Promise<BanxaTransactionResponse> => {
  try {
    const raw = await fetch(`${BACKEND_URL}/api/banxa${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
    const response = await raw.json()
    return response
  } catch (error) {
    console.error('error: ', error)
    Sentry.captureException(error)
    throw error
  }
}

export const banxaCreateNFTOrder = async (
  params: BanxaCreateNFTOrderParams
) => {
  return banxaCreateOrder('/orders/nft', params)
}

export const banxaCreateCryptoOrder = async (params: BanxaOrderParams) => {
  return banxaCreateOrder('/orders/crypto/buy', params)
}
