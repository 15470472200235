export interface SpinnerProps {
  centered?: boolean
  isLarge?: boolean
}

export default function Spinner({ centered, isLarge }: SpinnerProps) {
  return (
    <div
      className={`${centered && '!w-[40px] '} ${
        isLarge && '!w-[76px] my-8'
      } lds-ring`}
    >
      <div className={`${isLarge && '!w-16 !h-16'}`}></div>
      <div className={`${isLarge && '!w-16 !h-16'}`}></div>
      <div className={`${isLarge && '!w-16 !h-16'}`}></div>
      <div className={`${isLarge && '!w-16 !h-16'}`}></div>
    </div>
  )
}
