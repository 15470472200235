import transfer from '/assets/Icon/Transfer.svg'
import { Moment, NFT } from 'types'
import SelectionCard from 'components/TrophyCabinet/SelectionCard'

export interface OldToNewMomentSectionProps {
  oldMoment: NFT<Moment> | undefined
  newMoment: NFT<Moment> | undefined
}

const OldToNewMomentSection = (props: OldToNewMomentSectionProps) => {
  const { oldMoment, newMoment } = props

  return (
    <div className={`relative @container card-legend w-full max-w-[600px]`}>
      <div className="flex overflow-scroll rounded-md card-back ripper-skippers-moments">
        <div className="flex sm:gap-3 md:gap-6">
          {oldMoment && (
            <SelectionCard
              image={oldMoment.templateData.immutableData.thumbnail?.replaceAll(
                '%20',
                '%2520'
              )}
              name={oldMoment.templateData.immutableData.headline}
              player={oldMoment.templateData.immutableData.player}
              playersJumperNumber={0}
              tier={oldMoment.templateData.immutableData.tier}
              circulatingSupply={
                oldMoment.templateData.immutableData.circulatingSupply
              }
            />
          )}
          <div className="flexCenter">
            <div className="flex z-10 justify-center p-1 min-w-[24px] h-6 bg-white rounded-[50%] sm:p-2 sm:min-w-[40px] sm:h-[40px]">
              <img src={transfer} alt="Transfer" className="" />
            </div>
          </div>
          {newMoment && (
            <SelectionCard
              image={newMoment.templateData.immutableData.thumbnail?.replaceAll(
                '%20',
                '%2520'
              )}
              name={newMoment.templateData.immutableData.headline}
              player={newMoment.templateData.immutableData.player}
              playersJumperNumber={0}
              tier={newMoment.templateData.immutableData.tier}
              circulatingSupply={
                newMoment.templateData.immutableData.circulatingSupply
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default OldToNewMomentSection
