import * as fcl from '@onflow/fcl'
import * as Sentry from '@sentry/browser'

export const subToTxStatus = (txId: string, retryLimit = 3) => {
  return new Promise<any>((resolve, reject) => {
    const handleStatus = (value: any, err: any) => {
      if (err) {
        console.error('ERR: ', err)
        Sentry.captureException(err, { extra: { txId, retryLimit } })
        if (retryLimit > 0) {
          subToTxStatus(txId, retryLimit - 1)
            .then(resolve)
            .catch(reject)
        } else {
          reject(err)
        }
      } else {
        if (value.statusString === 'SEALED' && !value.errorMessage) {
          resolve(value)
        } else if (value.errorMessage) {
          reject(value.errorMessage)
        }
      }
    }
    fcl.tx(txId).subscribe(handleStatus)
  })
}

export const waitForSealed = async (txId: string) => {
  try {
    const status = await subToTxStatus(txId)
  } catch (error) {
    Sentry.captureException(error, { extra: { txId } })
    throw error
  }
}
