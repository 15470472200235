import * as Sentry from '@sentry/browser'
import { useState } from 'react'
import * as fcl from '@blocto/fcl'
import { useTransactions } from 'providers/TransactionsProvider'
import { useWallets } from 'providers/WalletsProvider'
// import { waitForSealed } from 'utils/flow'
import { useSnackbar } from './useSnackbar'
import { WRAP_USDC } from 'flow/transactions/wrapUSDC.tx'

export enum ExchangeStatus {
  LOADING,
  IDLE,
  EXCHANGING,
  DONE,
  ERROR
}

export default function useWrapUSDC() {
  const [status, setStatus] = useState<ExchangeStatus>(ExchangeStatus.IDLE)
  const { addTx } = useTransactions()
  const { openSnackbar } = useSnackbar()
  const { userAuth } = useWallets()

  async function wrapUSDC() {
    try {
      setStatus(ExchangeStatus.EXCHANGING)
      const transactionId = await fcl.send([
        fcl.transaction(WRAP_USDC),
        fcl.payer(userAuth),
        fcl.proposer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(transactionId.transactionId, 'Wrapping USDC')
      await fcl.tx(transactionId).onceSealed()
      // await waitForSealed(transactionId.transactionId)
      setStatus(ExchangeStatus.DONE)
    } catch (e) {
      console.error(e)
      openSnackbar('Error wrapping USDC: ' + e)
      setStatus(ExchangeStatus.ERROR)
      Sentry.captureException(e)
      throw e
    }
  }

  return {
    status,
    wrapUSDC
  }
}
