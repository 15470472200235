import * as fcl from '@blocto/fcl'
import * as Sentry from '@sentry/browser'

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL

export const premintGenesisBall = async (account: string, nftId: string) => {
  const raw = await fetch(`${BACKEND_URL}/api/genesis-ball/premint`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      account: account,
      nftId: nftId
    })
  })
  const response = await raw.json()
  if (raw.status === 422) {
    throw new Error(response.result)
  }
  return response
}

const getGenesisBallExchangeSignature = async (signable: any) => {
  const raw = await fetch(`${BACKEND_URL}/api/genesis-ball/sign`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ signable })
  })

  const response = await raw.json()
  if (raw.status === 422) {
    throw new Error(response.result)
  }

  return response.signature
}

export const genesisBallServerAuthorization = async (account: any) => {
  const addr = import.meta.env.VITE_AFL_CONTRACTS
  const keyId = 0

  return {
    ...account,
    tempId: `${addr}-${keyId}`,
    addr: fcl.sansPrefix(addr),
    keyId: Number(keyId),
    signingFunction: async (signable: any) => {
      const signature = await getGenesisBallExchangeSignature(signable)
      return {
        addr: fcl.withPrefix(addr),
        keyId: Number(keyId),
        signature
      }
    }
  }
}

// The flipside api query (cached in on our backend) fetches all the minted NFTs that have been withdrawn from the primary admin wallet and have not been burnt.
export const getGenesisBallTemplateIds = async () => {
  try {
    const raw = await fetch(`${BACKEND_URL}/api/flipside/gb-ids`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    const response = await raw.json()
    return response[0].TEMPLATEIDS
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
  }
}
