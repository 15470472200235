export const GET_BURN_EXCHANGE_TOKENS = `
import AFLBurnExchange from 0xAFLContracts

pub fun main(ids: [UInt64]): [UInt64] {
  let r: [UInt64] = []
  for id in ids {
      r.append(AFLBurnExchange.getTokenId(id: id))
  }
  return r
}
`
