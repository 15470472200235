import * as Sentry from '@sentry/browser'
import { useEffect, useMemo, useState } from 'react'
import * as fcl from '@blocto/fcl'
import { GET_BURN_EXCHANGE_TOKENS } from 'flow/scripts/getBurnExchangeTokens'
import { SWAP_DUPES } from 'flow/transactions/swapDupes.tx'
import { waitForSealed } from 'utils/flow'
import { useTransactions } from 'providers/TransactionsProvider'
import { useWallets } from 'providers/WalletsProvider'

export enum LoadingStatus {
  LOADING,
  IDLE,
  ERROR
}

export enum TransactionStatus {
  PENDING,
  INFLIGHT,
  SUCCESS
}

export default function useBurnExchange(ids: string[]) {
  const { userAuth } = useWallets()
  const [idsAvailable, setIdsAvailable] = useState<string[]>([])
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.IDLE)
  const [txStatus, setTxStatus] = useState<TransactionStatus>(
    TransactionStatus.PENDING
  )
  const { addTx } = useTransactions()

  useMemo(() => {
    if (!ids) return

    const getExchangeMoments = async () => {
      setStatus(LoadingStatus.LOADING)
      try {
        const res = await fcl.query({
          cadence: GET_BURN_EXCHANGE_TOKENS,
          args: (arg: any, t: any) => [
            arg(
              ids.map((id) => id.toString()),
              t.Array(t.UInt64)
            )
          ]
        })
        setIdsAvailable(res)

        setStatus(LoadingStatus.IDLE)
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
        setStatus(LoadingStatus.ERROR)
      }
    }

    getExchangeMoments()
  }, [ids.length])

  const swapAll = async (ids: number[]) => {
    if (!ids?.length) return
    try {
      setTxStatus(TransactionStatus.INFLIGHT)
      const tx = await fcl.send([
        fcl.transaction(SWAP_DUPES),
        fcl.args([
          fcl.arg(
            ids.map((id) => id.toString()),
            fcl.t.Array(fcl.t.UInt64)
          )
        ]),
        fcl.proposer(userAuth),
        fcl.payer(userAuth),
        fcl.authorizations([userAuth]),
        fcl.limit(9999)
      ])
      addTx(tx.transactionId, 'Exchanging Duplicate Moments')
      await waitForSealed(tx.transactionId)
      setTxStatus(TransactionStatus.SUCCESS)
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
  }

  return {
    swapAll,
    idsAvailable,
    status,
    txStatus
  }
}
