export const WRAP_USDC = `
import FungibleToken from 0xFungibleToken
import FiatToken from 0xFiatToken
import USDCFlow from 0xUSDCFlow
import MetadataViews from 0xMetadataViews

/// Any account that owns FiatToken could use this transaction
/// to convert their FiatToken.Vault to a USDCFlow.Vault

transaction() {

prepare(signer: AuthAccount) {

    // Get a reference to the signer's stored FiatToken vault
    let vaultRef = signer.borrow<&{FungibleToken.Provider, FungibleToken.Balance}>(from: FiatToken.VaultStoragePath)
  ?? panic("Could not borrow reference to the owner's Vault!")

    // Convert the FiatToken to USDCFlow
    let wrappedTokens <- USDCFlow.wrapFiatToken(<-vaultRef.withdraw(amount: vaultRef.balance))

    if let wrappedVaultRef = signer.borrow<&{FungibleToken.Receiver}>(from: USDCFlow.VaultStoragePath) {
        wrappedVaultRef.deposit(from: <-wrappedTokens)
    } else {
        // The signer has not set up a USDCFlow Vault yet
        // so store it in their storage
        signer.save(
            <-wrappedTokens,
            to: USDCFlow.VaultStoragePath
        )
    }

    if (signer.borrow<&USDCFlow.Vault>(from: USDCFlow.VaultStoragePath) == nil) {
        panic("Failed to create the USDCFlow Vault!")
    }


    // Set up the correct capabilities

    signer.unlink(USDCFlow.ReceiverPublicPath)
    signer.link<&USDCFlow.Vault{FungibleToken.Receiver}>(
        USDCFlow.ReceiverPublicPath,
        target: USDCFlow.VaultStoragePath
    )

    signer.unlink(USDCFlow.VaultPublicPath)
    signer.link<&USDCFlow.Vault{FungibleToken.Balance, MetadataViews.Resolver}>(
        USDCFlow.VaultPublicPath,
        target: USDCFlow.VaultStoragePath
    )
}
}
`
