export const GET_NFTS_IN_CHUNKS = `
  import AFLNFT from 0xAFLContracts

  pub fun main(nftIds:[UInt64]): {UInt64:AnyStruct} {
    var dict : {UInt64: AnyStruct} = {}
    for nftId in nftIds {
        var nftData = AFLNFT.getNFTData(nftId: nftId)
        var templateDataById =  AFLNFT.getTemplateById(templateId: nftData.templateId)
        var nftMetaData : {String:AnyStruct} = {}

        nftMetaData["mintNumber"] = nftData.mintNumber;
        nftMetaData["templateData"] = templateDataById;
        nftMetaData["id"] = nftId;
        dict.insert(key: nftId,nftMetaData)
    }
    return dict
  }
`
