import * as fcl from '@blocto/fcl'
import * as Sentry from '@sentry/browser'
import { OKTA_TOKE_LOCALSTORAGE_KEY } from './wishlist.service.api'
import { BE_ACCESS_TOKEN_LOCALSTORAGE_KEY } from 'hooks/useCurrentUser'

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL

const getSignature = async (signable: any) => {
  const raw = await fetch(`${BACKEND_URL}/sign`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ signable })
  })

  const response = await raw.json()
  if (raw.status === 422) {
    throw new Error(response.result)
  }

  return response.signature
}

export const serverAuthorization = async (account: any) => {
  const addr = import.meta.env.VITE_AFL_CONTRACTS
  const keyId = 0

  return {
    ...account,
    tempId: `${addr}-${keyId}`,
    addr: fcl.sansPrefix(addr),
    keyId: Number(keyId),
    signingFunction: async (signable: any) => {
      const signature = await getSignature(signable)
      return {
        addr: fcl.withPrefix(addr),
        keyId: Number(keyId),
        signature
      }
    }
  }
}

export const createTransaction = async (flowUser: any, transactionId: any) => {
  const response = await fetch(`${BACKEND_URL}/api/transaction`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      UserAddress: flowUser,
      TransactionId: transactionId
    })
  })
  if (response.status === 500) {
    throw new Error(await response.json())
  }
}

export const sendTxId = async (txId: string, combinationId: number) => {
  const raw = await fetch(`${BACKEND_URL}/api/tx/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      combinationId: combinationId,
      txId: txId
    })
  })
  const response = await raw.json()
  if (raw.status === 422) {
    throw new Error(response.result)
  }
  return response
}

export const oktaLogin = async (idToken: string) => {
  try {
    const response = await fetch(`${BACKEND_URL}/api/auth/okta/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ idToken })
    })

    if (!response.ok) {
      const result = await response.json()
      if (response.status === 401) {
        console.warn('Unauthorized access. Returning empty object.')
        return {}
      }
      throw new Error(result.msg)
    }

    const result = await response.json()
    return result
  } catch (error) {
    console.error('error', error)
    throw error
  }
}

export const registerOktaId = async (
  oktaId: string,
  acceptTerms?: boolean,
  optIn?: boolean
) => {
  let response
  if (!!acceptTerms || !!optIn) {
    response = await fetch(`${BACKEND_URL}/api/okta`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        oktaId: oktaId,
        acceptTerms: acceptTerms ? '1' : '0',
        optIn: optIn ? '1' : '0'
      })
    })
  } else {
    response = await fetch(`${BACKEND_URL}/api/okta`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        oktaId: oktaId
      })
    })
  }
  if (response.status === 500) {
    const r = await response.json()
    console.error('error', r)
    throw new Error(r)
  }
  const result = await response.json()
  return result
}

export const connectMagicWallet = async (DIDToken: string) => {
  const credentials = JSON.parse(
    localStorage.getItem(BE_ACCESS_TOKEN_LOCALSTORAGE_KEY) || '{}'
  ).accessToken

  if (!credentials) {
    throw new Error('No credentials found')
  }
  const response = await fetch(
    `${BACKEND_URL}/api/user-wallets/magic/connect`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${credentials}`
      },
      body: JSON.stringify({ DIDToken })
    }
  )
  return await response.json()
}

export const registerWallet = async (
  walletAddress: string,
  oktaId: string,
  isMagicWallet?: boolean
) => {
  if (!walletAddress || !oktaId) return
  const bodyData = {
    walletAddress: walletAddress,
    oktaId: oktaId,
    isMagic: isMagicWallet ? 1 : 0
  }

  const response = await fetch(`${BACKEND_URL}/api/wallet`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(bodyData)
  })

  if (response.status === 200) {
    return await response.json()
  } else {
    try {
      const data = await response.json()
      if (data === 'Already exists') {
        throw new Error('Record already exists')
      } else {
        console.error('data', data)
        throw new Error('Something went wrong while creating.')
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException({ error, bodyData, response })
      throw new Error('Something went wrong while creating.')
    }
  }
}

export const getUserWallets = async (oktaId?: string) => {
  const oktaCredentials = JSON.parse(
    localStorage.getItem(OKTA_TOKE_LOCALSTORAGE_KEY) || '{}'
  )
  const userId = oktaId || oktaCredentials?.idToken?.claims?.sub || undefined
  const queryString = new URLSearchParams(
    userId ? { oktaId: userId } : {}
  ).toString()

  try {
    const response = await fetch(`${BACKEND_URL}/api/wallet?${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })

    if (!response.ok) {
      const result = await response.json()
      throw new Error(
        result.msg || `Error fetching user wallets. Status: ${response.status}`
      )
    }

    if (response.status === 404) {
      return []
    }

    const result = await response.json()
    return result
  } catch (error) {
    if ((error as Error).message.includes('No data found for the provided'))
      return [] // Return empty array if no data found
    console.error('Error fetching user wallets:', (error as Error).message)
    throw error // Re-throw the error to propagate it to the caller
  }
}

export const lookupWallets = async (addresses: string[]) => {
  if (!addresses.length || addresses.join(',').length == 0) return []
  try {
    const response = await fetch(
      `${BACKEND_URL}/api/wallet/filter-by-addresses?walletAddresses=${addresses.join(
        ','
      )}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )

    if (!response.ok) {
      return []
    }

    const result = await response.json()
    return result
  } catch (error) {
    console.error('Error fetching user wallets:', (error as Error).message)
    return []
  }
}

export async function claimCollectible(
  promotionId: string,
  favouriteTeam: string,
  targetWallet: string
) {
  const oktaCredentials = JSON.parse(
    localStorage.getItem(OKTA_TOKE_LOCALSTORAGE_KEY) || '{}'
  )
  const oktaId = oktaCredentials?.idToken?.claims?.sub || undefined

  if (!oktaId)
    throw new Error('User not authenticated. Please log in to continue.')

  const response = await fetch(
    `${BACKEND_URL}/api/claim-collectible/${promotionId}?oktaId=${oktaId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ favouriteTeam, targetWallet })
    }
  )

  if (!response.ok) {
    const error = await response.text()
    throw new Error(error)
  }

  return await response.json()
}

// pack purchasing
export const precheck = async (
  userAddress: string,
  price: string,
  templateId: string,
  moonpay?: boolean
) => {
  let url = `${BACKEND_URL}/check?address=${userAddress}&price=${price}&templateId=${templateId}`
  if (moonpay) {
    url += `&moonpay=${moonpay}`
  }

  const oktaCredentials = JSON.parse(
    localStorage.getItem(OKTA_TOKE_LOCALSTORAGE_KEY) || '{}'
  )

  const raw = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${oktaCredentials?.accessToken?.accessToken}`
    }
  })

  const response = await raw.json()
  if (raw.status === 422) {
    throw new Error(response.result)
  }
  return response
}

export const getExchangeRate = async () => {
  const raw = await fetch(`${BACKEND_URL}/exchange-rate`)
  const response = await raw.json()
  return response
}

export const getMoonpaySignature = async (url: string) => {
  const raw = await fetch(`${BACKEND_URL}/api/moonpay/generate_signature`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ url })
  })
  const response = await raw.json()
  return response.url
}

export const getAllDuplicateIds = async () => {
  try {
    const raw = await fetch(`${BACKEND_URL}/api/flipside/dupe-ids`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    const response = await raw.json()
    return response[0].DUPLICATENFTIDS
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
  }
}

export const submitUserInfo = async (userInfo: any) => {
  try {
    const raw = await fetch(`${BACKEND_URL}/api/user-info/submit`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userInfo)
    })
    const response = await raw.json()
    return response
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
  }
}

export const getSiteSettings = async () => {
  try {
    const raw = await fetch(`${BACKEND_URL}/api/site-settings`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    const response = await raw.json()
    return response
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
  }
}
